import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from '../../../layout/admin/navigation/navigation';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';

import { Realm } from 'src/app/core/models/realm';
import { User } from 'src/app/core/models/user';
import { Company } from 'src/app/core/models/company';
import {
  ISummaryItemsResponse,
  SummaryItems,
  SummaryByVendors,
  ISummaryByVendorsResponse,
} from 'src/app/core/models/summary';
import {
  HeaderModel,
  SummaryItemEnum,
  SummaryByVendorsEnum,
} from '../header/header.model';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';

import { ItemService } from 'src/app/core/services/item.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { PusherService } from 'src/app/core/services/pusher.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { SummaryByVendorService } from 'src/app/core/services/summary-by-vendor.service';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { Location } from '@angular/common';
import { conditionalOperator } from '@stockaid/utils';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent extends BaseComponent implements OnInit {
  @Input() type: string;

  public navigation: any;
  breadcrumbList: Array<any> = [];
  public navigationList: Array<any> = [];

  summaryItems: HeaderModel[];
  summaryByVendors: HeaderModel[];
  isSummary = true;
  isShowManageHeader: boolean;
  isShowPurchasesHeader: boolean;
  isHideBreadCrumb: boolean;
  companyName: string;
  lastRealm: Realm;
  selectedCompanyKey: string;
  selectedUserKey: string;
  isCreatingSupplier: boolean;
  selectedSupplierKey: string;
  selectedShipmentKey: string;
  selectedPoKey: string;
  DEFAULT_COMPANY_KEY = '0';
  isFilteringNavigation = false;
  get currentCompany(): Company {
    return this.companyService.currentCompany();
  }
  get currentUser(): User {
    return this.authService.currentUser;
  }

  getBreadcrumbTitle(breadcrumbTitle: string) {
    return breadcrumbTitle === 'Supply' ? 'Purchase Orders' : breadcrumbTitle;
  }

  constructor(
    public nav: NavigationItem,
    private titleService: Title,
    public itemService: ItemService,
    private summaryByVendorService: SummaryByVendorService,
    private companyService: CompanyService,
    private headerService: HeaderService,
    private router: Router,
    private pusherService: PusherService,
    private authService: AuthenticationService,
    private location: Location
  ) {
    super();

    this.navigation = this.nav.get();
    this.type = 'theme2';

    this.setBreadcrumb();
  }

  ngOnInit(): void {
    let routerUrl: string;
    routerUrl = this.router.url;
    this.setShowingFromRoutes(routerUrl);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        routerUrl = this.router.url;
        this.setShowingFromRoutes(routerUrl);
      }
    });

    this.companyService.getCurrentCompany().subscribe(() => {
      this.getHeaderData();
    });

    this.pusherService.userChannel.bind('notification', (data) => {
      if (data.type === 'notice' || data.type === 'close') {
        this.getSummaryByVendor().subscribe();
        this.companyService
          .getCurrentCompany()
          .pipe(this.autoCleanUp())
          .subscribe(() => {
            this.getHeaderData();
          });
      }
    });

    routerUrl = this.sanitizeUrl(routerUrl);

    if (routerUrl && typeof routerUrl === 'string') {
      this.filterNavigation(routerUrl);
    }

    this.headerService
      .getCompanyName()
      .pipe(this.autoCleanUp())
      .subscribe(({ newName, companyKey }) => {
        if (companyKey === this.currentCompany.companyKey) {
          this.currentCompany.companyName = newName;
        }
      });

    this.location.onUrlChange((url) => {
      if (!url || this.isFilteringNavigation) {
        return;
      }

      this.isFilteringNavigation = true;
      this.breadcrumbList.length = 0;
      const activeLink = this.sanitizeUrl(url);
      this.filterNavigation(activeLink);
    });
  }

  setShowingFromRoutes(routerUrl: string): void {
    this.isShowManageHeader =
      routerUrl.includes('dashboard/manage') || routerUrl.includes('home');
    this.isShowPurchasesHeader = routerUrl.includes('purchases');
    this.isHideBreadCrumb =
      routerUrl.includes('restock-suggestions') &&
      routerUrl.includes('vendorName');
  }

  getHeaderData(): void {
    if (
      this.currentCompany?.companyKey &&
      this.currentCompany?.companyKey !== this.DEFAULT_COMPANY_KEY
    ) {
      this.getSummaryItems()
        .pipe(switchMap(() => this.getSummaryByVendor()))
        .subscribe();
    }
  }

  setBreadcrumb(): void {
    let routerUrl: string;
    this.router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        this.isFilteringNavigation = true;
        this.breadcrumbList.length = 0;
        const activeLink = this.sanitizeUrl(router.url);
        this.filterNavigation(activeLink);
      }
    });
  }

  filterNavigation(activeLink): void {
    let result = [];
    let title = 'Welcome';
    this.navigation.forEach((a) => {
      if (this.shouldConstructNavigation(a, activeLink)) {
        result = [
          {
            url: conditionalOperator('url' in a, a.url, false),
            title: a.title,
            breadcrumbs: conditionalOperator(
              'breadcrumbs' in a,
              a.breadcrumbs,
              true
            ),
            type: a.type,
          },
        ];
        title = a.title;
        return;
      }

      if (a.type !== 'group' || !('children' in a)) {
        return;
      }
      a.children.forEach((b) => {
        if (this.shouldConstructNavigation(b, activeLink)) {
          result = [
            {
              url: conditionalOperator('url' in b, b.url, false),
              title: b.title,
              breadcrumbs: conditionalOperator(
                'breadcrumbs' in b,
                b.breadcrumbs,
                true
              ),
              type: b.type,
            },
          ];
          title = b.title;
          return;
        }

        if (b.type !== 'collapse' || !('children' in b)) {
          return;
        }
        b.children.forEach((c) => {
          if (this.shouldConstructNavigation(c, activeLink)) {
            result = [
              {
                url: conditionalOperator('url' in b, b.url, false),
                title: b.title,
                breadcrumbs: conditionalOperator(
                  'breadcrumbs' in b,
                  b.breadcrumbs,
                  true
                ),
                type: b.type,
              },
              {
                url: conditionalOperator('url' in c, c.url, false),
                title: c.title,
                breadcrumbs: conditionalOperator(
                  'breadcrumbs' in c,
                  c.breadcrumbs,
                  true
                ),
                type: c.type,
              },
            ];
            title = c.title;
            return;
          }

          if (c.type !== 'collapse' || !('children' in c)) {
            return;
          }
          const tempResult = this.filterDeeperNavigation(
            activeLink,
            b,
            c,
            result,
            title
          );
          result = tempResult.result;
          title = tempResult.title;
        });
      });
    });
    this.navigationList = result;
    this.setNavigationTail(activeLink);
    this.titleService.setTitle(title + ' | ForecastRx');
    this.isFilteringNavigation = false;
  }

  getSummaryItems(): Observable<ISummaryItemsResponse> {
    return this.itemService
      .getSummaryItems(
        this.isSummary,
        this.currentCompany?.companyKey,
        this.currentCompany?.companyType,
        this.currentCompany?.summaryOnHandType
      )
      .pipe(
        tap((res) => {
          const initSummary = new SummaryItems();
          const resList = Object.entries(res?.model);
          this.summaryItems = (
            resList?.length ? resList : Object.entries(initSummary)
          )
            .filter(([name, total]) =>
              [
                SummaryItemEnum.onHandCount,
                SummaryItemEnum.onHandThirdPartyCount,
                SummaryItemEnum.missingVendorCount,
                SummaryItemEnum.olderThan30DaysCount,
                SummaryItemEnum.totalEstimatedStorageFees,
              ].includes(name as SummaryItemEnum)
            )
            .map(([name, total]) => {
              let different = 0;
              switch (name) {
                case SummaryItemEnum.onHandCount:
                  different = res?.model?.differentOutStockItemsCount;
                  break;
                case SummaryItemEnum.onHandThirdPartyCount:
                  different = res?.model?.differentWarehouseOutStockItemsCount;
                  break;
                case SummaryItemEnum.missingVendorCount:
                  different = res?.model?.differentMissingVendorCount;
                  break;
                case SummaryItemEnum.olderThan30DaysCount:
                  different = res?.model?.differentOlderThan30DaysCount;
                  break;
                case SummaryItemEnum.totalEstimatedStorageFees:
                  different = res?.model?.differenceTotalEstimatedStorageFees;
                  break;
              }

              return {
                key: name,
                name,
                total,
                isMoney: name === SummaryItemEnum.totalEstimatedStorageFees,
                different,
              };
            });

          if (this.currentCompany?.companyType === CompanyType.ASC) {
            this.summaryItems = this.summaryItems.filter(
              (item) => item.name !== SummaryItemEnum.missingVendorCount
            );
          } else {
            this.summaryItems = this.summaryItems.filter(
              (item) => item.name !== SummaryItemEnum.totalEstimatedStorageFees
            );
          }

          this.getSummaryName();
        })
      );
  }

  getSummaryByVendor(): Observable<ISummaryByVendorsResponse> {
    return this.summaryByVendorService
      .getSummaryByVendors(
        this.currentCompany?.companyKey,
        this.currentCompany?.companyType,
        this.currentCompany?.marketplaceId,
        this.currentCompany?.currencyCode
      )
      .pipe(
        tap((res) => {
          const initSummary = new SummaryByVendors();
          const resList = Object.entries(res?.model);

          this.summaryByVendors = (
            resList?.length ? resList : Object.entries(initSummary)
          )
            .filter(([name, total]) =>
              [
                SummaryByVendorsEnum.count,
                SummaryByVendorsEnum.uniqueItems,
                SummaryByVendorsEnum.totalPrice,
                SummaryByVendorsEnum.totalQty,
              ].includes(name as SummaryByVendorsEnum)
            )
            .map(([name, total]) => {
              let different = 0;
              switch (name) {
                case SummaryByVendorsEnum.count:
                  different = res?.model?.differentSuggestPOItems;
                  break;
                case SummaryByVendorsEnum.uniqueItems:
                  different = res?.model?.differentSuggestPOuniqueItems;
                  break;
                case SummaryByVendorsEnum.totalPrice:
                  different = res?.model?.differentSuggestPOTotalPrice;
                  break;
                case SummaryByVendorsEnum.totalQty:
                  different = res?.model?.differentSuggestPOTotalQty;
                  break;
              }
              return {
                key: name,
                name,
                total,
                isMoney: name === SummaryByVendorsEnum.totalPrice,
                different,
              };
            });

          this.getSummaryNameByVendors();
        })
      );
  }

  getSummaryName(): void {
    this.summaryItems?.forEach((item) => {
      switch (item.name) {
        case SummaryItemEnum.onHandCount:
          if (
            [CompanyType.ASC].includes(
              this.currentCompany.companyType as CompanyType
            )
          ) {
            item.name = 'Amazon Items';
          } else {
            item.name = 'Items Out of Stock';
          }
          break;

        case SummaryItemEnum.onHandThirdPartyCount:
          item.name = 'Warehouse Items';
          break;

        case SummaryItemEnum.olderThan30DaysCount:
          item.name = 'New Items';

          break;
        case SummaryItemEnum.missingVendorCount:
          item.name = 'No Suppliers Assigned';
          break;

        case SummaryItemEnum.totalEstimatedStorageFees:
          item.name = 'Total Estimated Storage Fees';
          break;
      }
    });
  }

  getSummaryNameByVendors(): void {
    this.summaryByVendors?.forEach((item) => {
      switch (item.name) {
        case SummaryByVendorsEnum.count:
          item.name = 'Suggested Purchase Orders';
          break;

        case SummaryByVendorsEnum.totalPrice:
          item.name = 'Total Cost of Suggested Purchase Orders';
          break;

        case SummaryByVendorsEnum.uniqueItems:
          item.name = 'Total Items on Suggested Purchase Orders';

          break;
        case SummaryByVendorsEnum.totalQty:
          item.name = 'Total Units on Suggested Purchase Orders';
          break;
      }
    });

    // Reorder summary data
    this.summaryByVendors?.sort((a, b) => {
      return (
        Object.keys(SummaryByVendorsEnum).indexOf(a.key) -
        Object.keys(SummaryByVendorsEnum).indexOf(b.key)
      );
    });
  }

  goToItemsWithFilter(name: SummaryItemEnum): void {
    const manageItemPath = '/dashboard/manage/items';
    const manageRestockSuggestionsPath =
      '/dashboard/shipments/restock-suggestions';
    const company = this.companyService.currentCompany();

    switch (name) {
      case SummaryItemEnum.onHandCount:
        this.router.navigate([`${manageItemPath}/out-of-stock`]);
        break;

      case SummaryItemEnum.onHandThirdPartyCount:
        this.router.navigate([`${manageItemPath}/out-of-stock-ware-house`]);
        break;

      case SummaryItemEnum.olderThan30DaysCount:
        this.router.navigate([`${manageItemPath}/new`]);
        break;

      case SummaryItemEnum.missingVendorCount:
        this.router.navigate([`${manageItemPath}/without-vendor`]);
        break;

      case SummaryItemEnum.totalEstimatedStorageFees:
        this.router.navigate(
          [`${manageRestockSuggestionsPath}/estimated-storage-fees`],
          {
            queryParams: { vendorName: '[All Suppliers]' },
          }
        );
        break;
    }

    this.headerService.sendCompany(company);
  }

  sanitizeUrl(url: string): string {
    switch (true) {
      case url.includes('items'):
        return url.replace(/(?:items).*$/, 'items');

      case url.includes('companies/edit'):
        this.selectedCompanyKey = url
          .match(/(?:companies\/edit\/)(.*)(?=\/)/)?.[0]
          ?.replace('companies/edit/', '');
        return url.replace(/(?:companies\/).*$/, 'companies/list');

      case url.includes('users/edit'):
        this.selectedUserKey = url
          .match(/(?:users\/edit\/)(.*)/)[0]
          ?.replace('users/edit/', '');
        return url.replace(/(?:users\/).*$/, 'users/list');

      case url.includes('shipments/suppliers'):
        if (url.includes('create')) {
          this.isCreatingSupplier = true;
        } else if (url.includes('edit')) {
          this.selectedSupplierKey = url
            .match(/(?:suppliers\/edit\/)(.*)/)[0]
            ?.replace('suppliers/edit/', '');
        }
        return url.replace(
          /(?:shipments\/suppliers).*$/,
          'shipments/suppliers'
        );

      case url.includes('shipments/manage-shipments'):
        if (url.includes('detail')) {
          this.selectedShipmentKey = url
            .match(/(?:manage-shipments\/detail\/)(.*)(?=\?)/)?.[0]
            ?.replace('manage-shipments/detail/', '');
        }
        return url.replace(
          /(?:shipments\/manage-shipments).*$/,
          'shipments/manage-shipments'
        );

      case url.includes('purchases/po-vault'):
        if (url.includes('detail')) {
          this.selectedPoKey = url
            .match(/(?:po-vault\/detail\/)(.*)/)?.[0]
            ?.replace('po-vault/detail/', '');
        }
        return url.replace(/(?:purchases\/po-vault).*$/, 'purchases/po-vault');

      case url.includes('shipments/create'):
        this.selectedShipmentKey = url
          .match(/(?:create\/)(.*)/)?.[0]
          ?.replace('create/', '');
        return url.replace(/(?:shipments\/create).*$/, 'shipments/create');

      case url.includes('shipments/restock-suggestions'):
        return url.replace(
          /(?:shipments\/restock-suggestions).*$/,
          'shipments/restock-suggestions'
        );

      case url.includes('manage/modify-forecast'):
        return url.replace(
          /(?:manage\/modify-forecast).*$/,
          'manage/modify-forecast'
        );
    }

    return url;
  }

  private setNavigationTail(activeLink: string) {
    switch (true) {
      case Boolean(this.selectedCompanyKey):
        this.navigationList.push({
          breadcrumb: true,
          title: this.selectedCompanyKey,
          url: false,
          mainTitle: 'Edit Company',
        });
        this.selectedCompanyKey = '';
        break;

      case Boolean(this.selectedUserKey):
        this.navigationList.push({
          breadcrumb: true,
          title: this.selectedUserKey,
          url: false,
          mainTitle: 'Edit User',
        });
        this.selectedUserKey = '';
        break;

      case this.isCreatingSupplier:
        this.navigationList.push({
          breadcrumb: true,
          title: 'Create',
          url: false,
          mainTitle: 'Create',
        });
        this.isCreatingSupplier = false;
        break;

      case Boolean(this.selectedSupplierKey):
        this.navigationList.push({
          breadcrumb: true,
          title: this.selectedSupplierKey,
          url: false,
          mainTitle: 'Edit Supplier',
        });
        this.selectedSupplierKey = '';
        break;

      case Boolean(this.selectedShipmentKey):
        this.navigationList.push({
          breadcrumb: true,
          title: this.selectedShipmentKey,
          url: false,
          mainTitle: activeLink.includes('shipments/create')
            ? 'Restock from Warehouse'
            : 'Shipment Details',
        });
        this.selectedShipmentKey = '';
        break;

      case Boolean(this.selectedPoKey):
        console.log(this.selectedPoKey);
        this.navigationList.push({
          breadcrumb: true,
          title: this.selectedPoKey,
          url: false,
          mainTitle: 'Purchase Order Details',
        });
        this.selectedPoKey = '';
        break;
    }
  }

  private filterDeeperNavigation(activeLink, b, c, result, title) {
    let tempResult = result;
    let tempTitle = title;
    c.children.forEach((d) => {
      if (d.type !== 'item' || !('url' in d) || d.url !== activeLink) {
        return;
      }
      tempResult = [
        {
          url: conditionalOperator('url' in b, b.url, false),
          title: b.title,
          breadcrumbs: conditionalOperator(
            'breadcrumbs' in b,
            b.breadcrumbs,
            true
          ),
          type: b.type,
        },
        {
          url: conditionalOperator('url' in c, c.url, false),
          title: c.title,
          breadcrumbs: conditionalOperator(
            'breadcrumbs' in c,
            c.breadcrumbs,
            true
          ),
          type: c.type,
        },
        {
          url: conditionalOperator('url' in d, d.url, false),
          title: d.title,
          breadcrumbs: conditionalOperator(
            'breadcrumbs' in c,
            d.breadcrumbs,
            true
          ),
          type: d.type,
        },
      ];
      tempTitle = d.title;
    });
    return { result: tempResult, title: tempTitle };
  }

  private shouldConstructNavigation(nav, activeLink) {
    return nav.type === 'item' && 'url' in nav && nav.url === activeLink;
  }
}
