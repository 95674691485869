import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from './resource-service';
import { Supply, ISupplyResponse } from '../models/supply';
import { SortAttribute } from 'src/app/core/infrastructure/classes/sort-attribute';
import { StockaidResponse } from '../misc/stockaid-response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupplyService extends ResourceService<Supply> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/supply');
  }

  public stockaidPaginated(
    offset: number,
    limit: number,
    sortAttributes: SortAttribute[],
    filters: []
  ): Observable<StockaidResponse<Supply[]>> {
    sortAttributes = sortAttributes || [];

    const body: any = {
      offset,
      limit,
      sort: sortAttributes,
      filter: [],
      operation: 'paginate',
    };

    return this.httpClient.post<StockaidResponse<Supply[]>>(this.apiUrl, body);
  }

  getSuppliesItemKey(itemKey: string): Observable<Supply[]> {
    return this.httpClient.get<ISupplyResponse>(`${this.apiUrl}?id=${encodeURIComponent(itemKey)}`).pipe(
      map((Isupply) => Isupply.model)
    );
  }

  hideSupplyByOrderQty(maxOrderQty: number) {
    const body = { maxOrderQty }
    return this.httpClient.put(`${this.apiUrl}/hide`, body );
  }
}
