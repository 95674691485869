export class Supply {
  supplyUuid: string = '';
  docType: string = '';
  orderKey: string = '';
  rowKey: string = '';
  refNum: string = '';
  vendorKey: string = '';
  vendorName: string = '';
  dueDate: Date = null;
  docDate: Date = null;
  imageUrl: string = '';
  itemKey: string = '';
  itemName: string = '';
  asin: string = '';
  fnsku: string = '';
  orderQty: number = 1;
  openQty: number = 0;
  purchasingSummariesUuid: string = '';
  documentUuid: string = '';
  reconciledStatus: string = '';
  notes: string = '';
  constructor(init?: Partial<Supply>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}

export interface ISupplyResponse {
  err: string;
  model: Supply[];
}
