import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderQtyAutofillType } from '@stockaid/shared-enums';
import { Observable } from 'rxjs';
import { IPurchaseOrderPayload } from '../infrastructure/interfaces/add-request.interface';
import { PurchaseOrder } from '../models/purchase-order';
import { ResourceService } from './resource-service';
import { Shipment } from '../models/shipment';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService extends ResourceService<PurchaseOrder> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/purchase-order');
  }

  createPO(
    purchaseOrder: PurchaseOrder,
    itemKeys: string[],
    removedKeys: string[],
    createFromVendor?: string
  ): Observable<PurchaseOrder> {
    return this.httpClient.post<PurchaseOrder>(`${this.apiUrl}`, {
      ...purchaseOrder,
      itemKeys,
      removedKeys,
      createFromVendor,
    });
  }

  createPOFromShipment(
    purchaseOrder: PurchaseOrder,
    shipment: Shipment
  ): Observable<PurchaseOrder> {
    return this.httpClient.post<PurchaseOrder>(`${this.apiUrl}/shipment`, {
      purchaseOrder,
      shipmentKey: shipment.key,
      supplierKey: shipment.addressFromKey,
      shipmentId: shipment.shipmentId || shipment.shipmentName
    });
  }

  updatePurchaseOrderOrderQty(
    purchaseOrderKey: string,
    autofillType: OrderQtyAutofillType,
    isChecked: boolean,
    marketplaceId?: string,
    currencyCode?: string
  ) {
    let params = new HttpParams();
    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }

    return this.httpClient.put<{ waitForJob: boolean }>(
      `${this.apiUrl}/${purchaseOrderKey}/update-order-qty/${autofillType}`,
      {
        isChecked,
        params,
      }
    );
  }

  downloadFile(
    purchaseOrderKey: string,
    payload: IPurchaseOrderPayload,
    marketplaceId?: string,
    currencyCode?: string
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams()
      .set('poNum', payload.poNum)
      .set('symbol', payload.symbol);

    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }
    return this.httpClient.post<HttpResponse<any>>(
      `${this.apiUrl}/${purchaseOrderKey}/download?${params.toString()}`,
      null,
      { observe: 'response' }
    );
  }
}
