
export enum GridName {
  Item = 'Item',
  Vendor = 'Vendor',
  Supply = 'Supply',
  Demand = 'Demand',
  Bom = 'Bom',
  Supplier = 'Supplier',
  ManageSupplier = 'Address Book',
  Shipment = 'Shipment',
  SupplierList = 'Supplier List',
  AllAvailableItems = 'All Available Items',
  ForecastShipmentItem = 'Forecast Shipment Item',
  AmazonShipmentItem  = 'Amazon Shipment Item',
  ForecastShipmentItemChosen = 'Forecast Shipment Item Chosen',
  ForecastShipmentItemValid = 'Forecast Shipment Item Valid',
  SuggestedPos ='Suggested POs',
  ItemsInPo = 'Items in PO',
  ItemStickers = 'Item Stickers',
  RestockSuggestion = 'Restock Suggestion',
  ForecastedHistorySetting = 'Forecasted History Setting',
  ItemSite = 'ItemSite',
  PurchaseOrder = 'PurchaseOrder',
  PurchaseOrderItem = 'PurchaseOrderItem'
}

const tooltips = {
  generic: {
    reconciledQty: 'Units that have been received',
    matches:
      'Amazon has received a shipment with items contained on the purchase order',
    dueDate: 'Estimated date of receipt',
    openQty: 'Outstanding units on a sales order or invoice',
    orderQty: 'Total units on a sales order or invoice',
    poNum: 'Purchase order reference identifier',
    vendorName: 'Common business name of your supplier',
    availableQty: 'Amount recognized as unreconciled on the shipment',
    itemName: 'Name your items are referred by, typically SKU',
    poAvailability: 'Outstanding units on a purchase order',
    quantity: 'Amount recognized as received in the shipment',
    receivedDate: 'Date that Amazon received your items',
    availablewarehouseqty:
      'Inventory outside of Amazon related to items on the shipment',
    lineItems: 'Unique items contained on the shipment',
    shipmentId: 'ID Amazon assigned to your inbound shipment',
    s30d: 'Historic sales over period of time',
    s90d: 'Historic sales over period of time',
    s365d: 'Historic sales over period of time',
    s7d: 'Historic sales over period of time',
    daysRemaining: 'Days left to place your order to stay in stock',
    lotMultipleQty: 'Multiple an item must be ordered by from your supplier',
    moq: 'Minimum quantity your supplier requires you to purchase at a time',
    sMtd: 'Historic sales over period of time',
    onNewPo: 'Quantity of an item on a new PO since your last forecast',
    openPurchaseOrders:
      'Quantity of an item on existing POs prior to the last forecast',
    daysLeft: 'Days your current inventory is expected to last',
    outOfStockDate: 'Date your current inventory is expected to run out',
    meanLtd: 'Calculated prediction of future sales',
    purchaseQty: 'Adjustable quantity you want to order today',
    truePurchaseQty:'Recommended purchase qty adjusted for MOQ and case pack qty',
    recommendedQty: 'Recommended order qty based on forecast models only.',
    recommendedOrderDate: 'Latest order by date to stay in stock',
    safetyStockLtd: 'Cushion added for highly variable or important items',
    total: 'Total dollar commitment for all units',
    vendorPrice: 'Adjustable cost per unit of a given item',
    sYtd: 'Historic sales over period of time',
    earliestRecommendedOrderDate: 'Latest order by date to stay in stock',
    totalPrice: 'Total dollar commitment for all units',
    totalQty: 'Total units on the suggested PO',
    uniqueItems: 'Total distinct items included on the suggested PO',
    createdAt: 'The date the purchase order was created',
    documentTotal: 'Cost of all of the items on the PO',
    poRefNum: 'Purchase order reference identifier',
    qtyOrdered: 'Number of units contained with the purchase order',
    qtyReceived: 'Number of units to date that have been received',
    view: 'View or modify existing POs',
    asin: 'Amazon standard identification number',
    refNum: 'Order reference number',
    description: 'Textual, referential description of your Item',
    isHidden: 'Hides from your forecasting and purchase recommendations',
    doNotOrder: 'Hides from your purchasing recommendations',
    doNotRestock: 'Hides from your restock recommendations',
    name: 'Name your items are referred by, typically SKU',
    leadTime:
      'Days it takes to become saleable from the time you place the order',
    orderInterval:
      'The preferred number of days between placing purchase orders',
    lotMultipleItemName:
      'You purchase an item as a different SKU than you sell it as',
    serviceLevel: 'Select safety stock level ranging from 0-10 with 0 being no safety stock and 10 being the most safety stock',
    tag: 'User defined categorization field',
    useBackfill: 'Toggles back-filled data in order to simulate seasonality',
    useHistoryOverride:
      'Toggles history overrides in order to improve accuracy',
    email: 'Supplier email address',
    onHand: 'Inventory you have in storage or at a place of sale',
    onHandMin: 'Min inventory in storage or at a place of sale',
    warehouseQty: 'Inventory you have outside of Amazon FBA and FBM',
    warehouseQtyMin: 'Min inventory you have outside of Amazon',
    onHandThirdPartyMin:
      'Min inventory to keep in stock outside an Amazon facility',
    parentName: 'The SKU (Item Name) of the Kit',
    childName: 'The SKU (Item Name) of a component item in the kit',
    qty: 'The amount of the component item included in one kit',
    docDate: 'The day the purchase order was created.',
    history: 'History',
    earliestDueDate: 'Latest order by date to stay in stock',
    snapshotQty: 'Demand - Purchase Orders',
    lastOrderDate: 'Last Order Date',
    nextScheduledOrderDate: 'Next Scheduled Order Date',
    daysUntilNextScheduledOrder: 'Days Until Next Scheduled Order',
    openSalesOrders: 'Open Sales Orders',
    createdAtItem: 'The date the item was created',
    isHiddenDemand: 'Removes demand from sales history'
  },
  ASC: {
    onHand: 'Inventory you have at an Amazon facility',
    onHandMin: 'Min inventory to keep in stock at an Amazon facility',
    onHandThirdParty: 'Inventory you have outside of Amazon FBA and FBM',
    localQty: 'Inventory you have outside of Amazon FBA and FBM',
    onHandThirdPartyMin:
      'Min inventory to keep in stock outside an Amazon facility',
    links: 'Links to your public and private item details',
    inbound: 'Inbound',
    inboundPrice: 'Amazon Price',
    inboundSalesLast30Days: 'Amazon Sales Last 30 Days',
    inboundAvailable: 'Inbound Available',
    inboundFcTransfer: 'FC Transfer',
    inboundFcProcessing: 'FC Processing',
    inboundCustomerOrder: 'FC Customer Order',
    inboundUnfulfillable: 'Unfulfillable',
    inboundAlert: 'Amazon Inventory Alert',
    inboundWorking: 'Inbound Working',
    mwsFulfillmentFee: 'Fulfillment Fee',
    caseQty: 'Units in a single case pack',
    receiveDate: 'Update to use Expedite Recommendation',
    [`onHandThirdParty${GridName.ItemsInPo}`]: 'Warehouse Qty + AWD Inventory',
    [`onHandThirdParty${GridName.AllAvailableItems}`]: 'Warehouse Qty + AWD Inventory',
    [`refNum${GridName.Shipment}`]: 'Enter PO Num to link PO to shipments so inventory is not duplicated and tracked only on shipment',
    [`shipmentId${GridName.PurchaseOrder}`]: 'Enter FBA Shipment IDs for the PO to auto-track shipment receiving instead of updating the PO'
  },
  QBFS: {
    onHandThirdParty: 'Inventory you have outside of a place of sale',
  },
  QBO: {
    onHandThirdParty: 'Inventory you have outside of a place of sale',
  },
  CSV: {
    onHandThirdParty: 'Inventory you have outside of a place of sale'
  },
};

export function getTip(field: string, companyType: string, gridName: GridName | '' = ''): string {
  let tip = tooltips?.[companyType]?.[field + gridName] || tooltips?.[companyType]?.[field];
  if (!tip) {
    tip = tooltips.generic[field + gridName] || tooltips.generic[field];
  }

  return tip || '';
}

export enum CountryCode {
  US = 'US'
}

export const ICON_FORECAST = 'assets/images/forecast_icon.svg';
