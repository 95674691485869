<div class="generic-item-modal">
  <div class="modal-header text-center">
    <div class="modal-title w-100">
      <h5 class="text-white">{{ modalTitle || "" }}</h5>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card generic-item-modal__card">
      <form class="row" *ngIf="genericItemForm" [formGroup]="genericItemForm">
        <div
          class="col-4 p-2"
          [ngClass]="{'d-none': forbiddenFields.includes(metadataField.field)}"
          *ngFor="let metadataField of metadataFields">
          <label
            class="m-r-5"
            *ngIf="!forbiddenFields.includes(metadataField.field)"
            >{{ metadataField.displayName }}</label
          >
          <ng-container
            *ngIf="!forbiddenFields.includes(metadataField.field)"
            [ngSwitch]="metadataField.displayControl?.input"
          >
            <input
              *ngSwitchCase="DisplayControlInput.Text"
              type="text"
              class="form-control generic-item-modal__input"
              [class.generic-item-modal__input--not-allowed]="
                genericItemForm.get(metadataField.field)?.disabled
              "
              [formControl]="genericItemForm.get(metadataField.field)"
            />
            <input
              *ngSwitchCase="DisplayControlInput.Email"
              type="email"
              class="form-control generic-item-modal__input"
              [class.generic-item-modal__input--not-allowed]="
                genericItemForm.get(metadataField.field)?.disabled
              "
              [formControl]="genericItemForm.get(metadataField.field)"
            />
            <input
              *ngSwitchCase="DisplayControlInput.Integer"
              decimalNumber
              type="number"
              class="form-control generic-item-modal__input"
              [class.generic-item-modal__input--not-allowed]="
                genericItemForm.get(metadataField.field)?.disabled
              "
              [formControl]="genericItemForm.get(metadataField.field)"
            />
            <input
              *ngSwitchCase="DisplayControlInput.Decimal"
              decimalNumber
              type="number"
              class="form-control generic-item-modal__input"
              [class.generic-item-modal__input--not-allowed]="
                genericItemForm.get(metadataField.field)?.disabled
              "
              [formControl]="genericItemForm.get(metadataField.field)"
            />
            <kendo-datepicker
              *ngSwitchCase="DisplayControlInput.Date"
              class="generic-item-modal__input"
              calendarType="classic"
              [class.generic-item-modal__input--not-allowed]="
                genericItemForm.get(metadataField.field)?.disabled
              "
              [formControl]="genericItemForm.get(metadataField.field)"
              placeholder="mm/dd/yyyy"
              [style.width.%]="100"
              format="{{FORMAT_DATE}}"
              [formatPlaceholder]="FORMAT_PATTERN"
            ></kendo-datepicker>
            <app-list-input
              *ngSwitchCase="DisplayControlInput.List"
              [group]="genericItemForm"
              [control]="genericItemForm.get(metadataField.field)"
              [controlName]="metadataField.field"
              [source]="metadataField.displayControl.source"
              [textField]="metadataField.displayControl.textField"
              [itemName]="itemName"
              [valueField]="metadataField.displayControl.valueField"
            >
            </app-list-input>

            <app-lookup-input
              *ngSwitchCase="DisplayControlInput.LookUp"
              [group]="genericItemForm"
              [lookUpConfig]="metadataField.displayControl.lookUpConfig"
              [control]="genericItemForm.get(metadataField.field)"
              [controlName]="metadataField.field"
              (shouldEnableChild)="enableChild($event)"
              [isDisabled]="
                metadataField.field === 'childName' && !isChildEnabled
              "
              [parentKey]="
                metadataField.field === 'childName' ? lookupParentKey : ''
              "
            ></app-lookup-input>

            <input
              *ngSwitchCase="DisplayControlInput.Checkbox"
              type="checkbox"
              class="generic-item-modal__input"
              [class.generic-item-modal__input--not-allowed]="
                genericItemForm.get(metadataField.field)?.disabled
              "
              [formControl]="genericItemForm.get(metadataField.field)"
            />

            <div *ngSwitchCase="DisplayControlInput.Select">
              <kendo-dropdownlist
                *ngIf="metadataField.field !== 'serviceLevel'; else serviceLevelDropDown"
                [data]="metadataField.displayControl.selectSource"
                [formControl]="formGroup.get(metadataField.field)"
                textField="display"
                valueField="val"
                [valuePrimitive]="true"
              ></kendo-dropdownlist>
    
              <ng-template #serviceLevelDropDown>
                <kendo-dropdownlist
                  class="w-100 d-block"
                  [popupSettings]="{ height: 200, width: 300 }"
                  [data]="metadataField.displayControl.selectSource"
                  [formControl]="genericItemForm.get(metadataField.field)"
                  textField="display"
                  valueField="val"
                  [valuePrimitive]="true"
                >
                  <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <div
                      class="dropdown-list-item"
                      [style]="{
                        'background-color': dataItem.bgColor,
                        'color': getContrastYIQ(dataItem.bgColor)
                      }"
                    >
                      <span class="pl-2 d-inline-block">{{ dataItem.display }}</span>
                    </div>
                  </ng-template>
                </kendo-dropdownlist>
              </ng-template>
            </div>

            <input
              *ngSwitchDefault
              type="text"
              class="form-control generic-item-modal__input"
              [class.generic-item-modal__input--not-allowed]="
                genericItemForm.get(metadataField.field)?.disabled
              "
              [formControl]="genericItemForm.get(metadataField.field)"
            />
          </ng-container>
          <div
            *ngIf="
              genericItemForm.get(metadataField.field)?.invalid &&
              !(isNew && genericItemForm.get(metadataField.field).untouched) &&
              !forbiddenFields.includes(metadataField.field)
            "
            class="text-danger text-small"
          >
            <span
              *ngIf="
                genericItemForm.get(metadataField.field).hasError('required')
              "
            >
              <small>{{ metadataField.displayName }} is required. </small>
            </span>
            <span
              *ngIf="genericItemForm.get(metadataField.field).hasError('max')"
            >
              <small>Maximum is {{ getMax(metadataField) }}. </small>
            </span>
            <span
              *ngIf="genericItemForm.get(metadataField.field).hasError('min')"
            >
              <small>Minimum is {{ getMin(metadataField) }}. </small>
            </span>
            <span
              *ngIf="genericItemForm.get(metadataField.field).hasError('email')"
            >
              <small>Invalid email address. </small>
            </span>
            <span
              *ngIf="
                genericItemForm.get(metadataField.field).hasError('isANumber')
              "
            >
              <small>{{ metadataField.displayName }} must be a number.</small>
            </span>
            <span
              *ngIf="
                genericItemForm
                  .get(metadataField.field)
                  ?.hasError('divisibleBy')
              "
            >
              <small>{{
                genericItemForm
                  .get(metadataField.field)
                  ?.getError("divisibleBy")
              }}</small>
            </span>
            <span
              *ngIf="
                genericItemForm
                  .get(metadataField.field)
                  ?.hasError('lessThanOrEqual')
              "
            >
              <small>{{
                genericItemForm
                  .get(metadataField.field)
                  ?.getError("lessThanOrEqual")
              }}</small>
            </span>
          </div>
          <span
            *ngIf="metadataField.field === 'dueDate' && isDateConflict"
            class="text-danger text-small"
          >
            <small>The Due Date must be greater than the Doc Date</small>
          </span>
        </div>
      </form>
    </div>
  </div>

  <div class="modal-footer p-10">
    <button
      type="button"
      class="btn btn-danger mr-2"
      (click)="activeModal.dismiss('Cross click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-success"
      (click)="saveItem()"
      [disabled]="genericItemForm.invalid"
      [ngStyle]="{ cursor: genericItemForm.valid ? 'pointer' : 'not-allowed' }"
    >
      Save
    </button>
  </div>
</div>
