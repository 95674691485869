import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';

import { Item } from 'src/app/core/models/item';
import {
  TimePeriod,
  CoverageRequired,
  CoverageAvailable,
  AdjustSwitchType,
} from './sale-velocity.constant';
import { RestockSuggestionService } from 'src/app/core/services/restock-suggestion.service';
import { RestockSuggestion } from 'src/app/core/models/restock-suggestion';
import _ from 'lodash';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { HeaderService } from 'src/app/core/services/header.service';
import { SALES_VELOCITY_SETTINGS } from 'src/app/core/constants/sales-velocity.constant';
import { formatDate } from '@angular/common';
import { SalesVelocitySettingsType } from '@stockaid/shared-enums';
import { RestockType } from 'src/app/core/infrastructure/enums/restock-type.enum';
import { CompanyService } from 'src/app/core/services/company.service';
import { VendorService } from 'src/app/core/services/vendor.service';
import { Vendor } from './../../../../core/models/vendor';
import { ItemService } from 'src/app/core/services/item.service';
import { SnotifyService } from 'ng-snotify';
import { Subject, Observable, of, throwError } from 'rxjs';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { conditionalOperator } from '@stockaid/utils';
import { COMMON_FORMAT_DATE } from 'src/app/core/constants/format-date.constant';

@Component({
  selector: 'app-sale-velocity-calculation',
  templateUrl: './sale-velocity-calculation.component.html',
  styleUrls: ['./sale-velocity-calculation.component.scss'],
})
export class SaleVelocityCalculationComponent
  extends BaseComponent
  implements OnInit
{
  @Input() choosenItem: Item;
  @Input() restockType: RestockType;
  @Input() isTotalComponentDataOn = false;
  @Input() salesVelocitySettingsType: SalesVelocitySettingsType =
    SalesVelocitySettingsType.restockAMZ;

  readonly SALES_VELOCITY_SETTINGS = SALES_VELOCITY_SETTINGS;
  readonly FORMAT_DATE = COMMON_FORMAT_DATE

  get currentCompany() {
    return this.companyService.currentCompany();
  }

  currentVendor: Vendor;

  constructor(
    public activeModal: NgbActiveModal,
    private restockSuggestionService: RestockSuggestionService,
    private headerService: HeaderService,
    private companyService: CompanyService,
    private vendorService: VendorService,
    private itemService: ItemService,
    private snotifyService: SnotifyService
  ) {
    super();
  }

  readonly RestockType = RestockType;
  readonly AdjustSwitchType = AdjustSwitchType;

  SUGGESTED_DATA;
  SALES_VELOCITY_TABLE_DATA;
  COVERAGE_REQUIRED_TABLE_DATA;
  COVERAGE_AVAILABLE_TABLE_DATA;
  restockSuggestion: RestockSuggestion;

  showSalesVelocity = true;
  showCoverageRequired = true;
  showCoverageAvailable = true;
  showForecastPopup = false;
  itemName = '';
  isOpenToogleAverageDailySalesRate = false;
  isOpenToogleRequiredInventory = false;
  isOpenToogleInventoryAvailable = false;
  hasAdjustedToggleChanged = false;
  isUsingAdjustedDailySalesRate = true;
  isAdjustingDailySalesRateForOutOfStock = false;
  isSaving = false;
  saveAdjSwitchForItem$ = new Subject<AdjustSwitchType>();

  get modalTitle(): string {
    if (
      this.salesVelocitySettingsType === SalesVelocitySettingsType.purchasing
    ) {
      return 'Daily Sales Rate Calculations';
    }
    return this.restockType === RestockType.Warehouse
      ? 'Restock from Warehouse Calculations'
      : 'Restock from Suppliers Calculations';
  }

  get updatedDailySalesRate(): Record<string, string | number | boolean> {
    if (!this.restockSuggestion) {
      return {};
    }

    return {
      itemKey: this.restockSuggestion.key,
      suggShip: this.restockSuggestion.suggShip,
      suggReorder: this.restockSuggestion.suggReorder,
      hasAdjustedToggleChanged: this.hasAdjustedToggleChanged,
    };
  }

  get isAdjustingOutOfStockSwitchDisabled(): boolean {
    const { sv2d, sv7d, sv14d, sv30d, sv60d, sv90d, sv180d, outOfStock2d } =
      this.restockSuggestion;
    return (
      (!sv2d && !sv7d && !sv14d && !sv30d && !sv60d && !sv90d && !sv180d) ||
      (!sv2d && !outOfStock2d)
    );
  }

  get isCompanyTypeASC(): boolean {
    return this.currentCompany?.companyType === CompanyType.ASC;
  }

  get isChildItem(): boolean {
    return (
      this.restockSuggestion?.isChild &&
      this.restockSuggestion?.salesVelocitySettingsType ===
        SalesVelocitySettingsType.purchasing
    );
  }

  get isPurchasing(): boolean {
    return (
      this.salesVelocitySettingsType === SalesVelocitySettingsType.purchasing
    );
  }

  ngOnInit(): void {
    this.saveAdjSwitchForItem$
      .pipe(
        this.autoCleanUp(),
        debounceTime(400),
        switchMap((adjustSwitchType) =>
          this.saveAdjSwitchForItem(adjustSwitchType)
        )
      )
      .subscribe();

    if (!this.choosenItem.orderInterval && this.choosenItem.vendorKey) {
      this.vendorService
        .getById(this.choosenItem.vendorKey)
        .pipe(this.autoCleanUp())
        .subscribe((vendor) => {
          this.currentVendor = vendor;
        });
    }

    this.itemName = this.choosenItem.name || this.choosenItem['sku'];

    const item$: Observable<Item> = conditionalOperator(
      !!this.choosenItem?.name,
      of(this.choosenItem),
      this.itemService.getById(
        this.choosenItem?.key || (this.choosenItem as any)?.itemKey
      )
    );

    item$
      .pipe(
        this.autoCleanUp(),
        tap((item: Item) => (this.choosenItem = item)),
        switchMap(() =>
          conditionalOperator(
            !this.choosenItem.orderInterval && !!this.choosenItem.vendorKey,
            this.vendorService.getById(this.choosenItem.vendorKey),
            of(null)
          )
        ),
        tap((vendor: Vendor) => (this.currentVendor = vendor)),
        switchMap(() => {
          return this.restockSuggestionService.getByIdWithType(
            this.choosenItem?.key,
            this.salesVelocitySettingsType
          );
        }),
        catchError((err) => {
          this.showForecastPopup = true;
          return throwError(err);
        })
      )
      .subscribe((restockSuggestion: RestockSuggestion) => {
        if (!restockSuggestion) {
          this.showForecastPopup = true;
          return;
        }

        this.restockSuggestion = restockSuggestion;
        this.reloadAllData();
      });
  }

  toogleAverageDailySalesRate() {
    this.isOpenToogleAverageDailySalesRate =
      !this.isOpenToogleAverageDailySalesRate;
  }

  toogleRequiredInventory() {
    this.isOpenToogleRequiredInventory = !this.isOpenToogleRequiredInventory;
  }

  toogleInventoryAvailable() {
    this.isOpenToogleInventoryAvailable = !this.isOpenToogleInventoryAvailable;
  }

  openForecastModal(): void {
    this.headerService.requestToOpenForecastSubject.next();
    this.activeModal.dismiss();
  }

  switchSalesVelocity() {
    this.showSalesVelocity = !this.showSalesVelocity;
  }

  switchCoverageRequired() {
    this.showCoverageRequired = !this.showCoverageRequired;
  }

  switchCoverageAvailable() {
    this.showCoverageAvailable = !this.showCoverageAvailable;
  }

  getTotal(list, field: string): number {
    const total = list.reduce((acc, data) => {
      return acc + data[field];
    }, 0);

    return Number.isInteger(total) ? total : total.toFixed(2);
  }

  calculateSuggQtyByRestockType(restockSuggestion: RestockSuggestion): any {
    let coverageRequired = 0;
    let suggestedReorderQty = 0;
    if (this.restockType === RestockType.Warehouse) {
      // Ignore supplier lead time.
      coverageRequired =
        restockSuggestion.coverageRequired -
        restockSuggestion.qtySupplierLeadTime +
        restockSuggestion.qtyToLocalLeadTime;
      suggestedReorderQty =
        coverageRequired - restockSuggestion.coverageAvailable - restockSuggestion.coverageOrderQty;
    } else {
      // Ignore warehouse lead time.
      coverageRequired = restockSuggestion.coverageRequired;
      suggestedReorderQty =
        coverageRequired - restockSuggestion.coverageAvailable - restockSuggestion.coverageOrderQty;
    }

    return {
      coverageRequired,
      suggestedReorderQty: Math.ceil(suggestedReorderQty),
    };
  }

  toSuggestedData(restockSuggestion: RestockSuggestion) {
    if (
      this.salesVelocitySettingsType === SalesVelocitySettingsType.restockAMZ
    ) {
      const { coverageRequired, suggestedReorderQty } =
        this.calculateSuggQtyByRestockType(restockSuggestion);
      restockSuggestion.coverageRequired = coverageRequired;
      restockSuggestion.suggReorder = suggestedReorderQty;
    }

    const casePackQuantity = restockSuggestion.casePackQuantity || 1;
    const cases = conditionalOperator(
      restockSuggestion.suggReorder > 0,
      Math.ceil(restockSuggestion.suggReorder / casePackQuantity),
      0
    );
    const units = casePackQuantity * cases;
    const unitsTextUnder = conditionalOperator(units > 1, 'units', 'unit');
    const casesTextUnder = conditionalOperator(cases > 1, 'cases', 'case');

    const suggestedRestockData =
      this.salesVelocitySettingsType === SalesVelocitySettingsType.purchasing
        ? []
        : [
            {
              title: 'Recommended Restock Quantity',
              unitNumber: conditionalOperator(
                restockSuggestion.suggShip < 0,
                0,
                restockSuggestion.suggShip
              ),
              textUnder: null,
              data: [
                {
                  field: 'Ideal Max Quantity at FBA',
                  operator: null,
                  value: restockSuggestion.targetQtyOnHand || 0,
                  hasUnderLine: false,
                  hasSpacing: false,
                },
                {
                  field: 'Required Inventory to cover Warehouse Lead-time',
                  operator: '+',
                  value: restockSuggestion.qtyToLocalLeadTime || 0,
                  hasUnderLine: false,
                  hasSpacing: false,
                },
                {
                  field: 'Current Amazon On Hand',
                  operator: '-',
                  value: restockSuggestion.currentAmazonInventory || 0,
                  hasUnderLine: true,
                  hasSpacing: false,
                },
                {
                  field: 'Warehouse Quantity Needed',
                  operator: '=',
                  value:
                    restockSuggestion.targetQtyOnHand +
                    restockSuggestion.qtyToLocalLeadTime -
                    restockSuggestion.currentAmazonInventory,
                  hasUnderLine: false,
                  hasSpacing: true,
                },
                {
                  field: 'Warehouse Inventory Available',
                  operator: '=',
                  value: restockSuggestion.localQty || 0,
                  hasUnderLine: false,
                  hasSpacing: false,
                },
              ],
            },
          ];

    this.SUGGESTED_DATA = [
      ...suggestedRestockData,
      {
        title: 'Recommended Purchase Order Quantity',
        unitNumber: conditionalOperator(
          restockSuggestion.suggReorder < 0,
          0,
          restockSuggestion.suggReorder
        ),
        textUnder: `or round to ${units} ${unitsTextUnder} (${cases} ${casesTextUnder})`,
        data: [
          {
            field: 'Units Required',
            operator: null,
            value: restockSuggestion.coverageRequired || 0,
            hasUnderLine: false,
            hasSpacing: false,
          },
          {
            field: 'Units Available',
            operator: '-',
            value: restockSuggestion.coverageAvailable || 0,
            hasUnderLine: false,
            hasSpacing: false,
          },
          {
            field: 'Units on PO',
            operator: '-',
            value: restockSuggestion.coverageOrderQty || 0,
            hasUnderLine: true,
            hasSpacing: false,
            ...(this.currentCompany?.isUsingAWDInventoryQty
              ? { tooltip: 'Open Qty + Inbound to AWD' }
              : {}
            )
          },
          {
            field: 'PO Quantity Required',
            operator: '=',
            value:
              restockSuggestion.coverageRequired -
              restockSuggestion.coverageAvailable -
              restockSuggestion.coverageOrderQty,
            hasUnderLine: false,
            hasSpacing: true,
          },
          {
            field: 'Minimum PO Qty',
            operator: null,
            value: restockSuggestion.moq || 0,
            hasUnderLine: false,
            hasSpacing: false,
          },
          {
            field: 'Case Pack Quantity',
            operator: null,
            value: casePackQuantity,
            hasUnderLine: false,
            hasSpacing: false,
          },
        ],
      },
    ];
  }

  toVelocityData(restockSuggestion: RestockSuggestion) {
    if (
      restockSuggestion.salesVelocityType === SALES_VELOCITY_SETTINGS.dateRange
    ) {
      const startDate = formatDate(
        restockSuggestion.salesVelocitySettingData.startDate,
        this.FORMAT_DATE,
        'en'
      );
      const endDate = formatDate(
        restockSuggestion.salesVelocitySettingData.endDate,
        this.FORMAT_DATE,
        'en'
      );
      this.SALES_VELOCITY_TABLE_DATA = {
        headers: ['Period', 'Daily Sales Rate', 'Adj. Daily Sales Rate'],
        data: [
          {
            field: '2day',
            displayName: `${startDate} - ${endDate}`,
            outOfStockDays: restockSuggestion.outOfStockDays,
            salesVelo: restockSuggestion.svDateRange || 0,
            adjVelo: restockSuggestion.adjSvDateRange || 0,
          },
        ],
      };
      return;
    }

    const settingData = restockSuggestion.salesVelocitySettingData;
    const weightedData: any = {
      ...this.constructWeightedData(restockSuggestion, settingData),
      weightedForecastedDemand: this.roundFloatNumber(
        ((restockSuggestion.svDemand || 0) *
          (settingData.percentForecasted || 0)) /
          100
      ),
    };

    this.SALES_VELOCITY_TABLE_DATA = {
      headers: conditionalOperator(
        this.isUsingAdjustedDailySalesRate && !this.isTotalComponentDataOn,
        [
          'Period',
          'Daily Sales Rate',
          'Adj. Daily Sales Rate',
          'Weight (%)',
          'Weighted (units/day)',
        ],
        [
          'Period',
          'Adj. Daily Sales Rate',
          'Daily Sales Rate',
          'Weight (%)',
          'Weighted (units/day)',
        ]
      ),
      data: [
        ...this.constructVelocityData(
          restockSuggestion,
          settingData,
          weightedData
        ),
        {
          field: 'forecastedDemand',
          displayName: TimePeriod.forecastedDemand,
          salesVelo: restockSuggestion.svDemand || 0,
          adjVelo: restockSuggestion.svDemand || 0,
          weight: (settingData.percentForecasted || 0) + '%',
          weighted: weightedData.weightedForecastedDemand,
        },
        {
          field: 'totalUnitsDays',
          displayName: TimePeriod.totalUnitsDay,
          weighted: this.roundFloatNumber(
            _.sum([
              weightedData.weightedForecastedDemand,
              weightedData.weighted180day,
              weightedData.weighted90day,
              weightedData.weighted60day,
              weightedData.weighted30day,
              weightedData.weighted14day,
              weightedData.weighted7day,
              weightedData.weighted2day,
            ])
          ),
        },
      ],
    };
    if (!this.isCompanyTypeASC || this.isTotalComponentDataOn) {
      this.SALES_VELOCITY_TABLE_DATA.headers = [
        'Period',
        'Daily Sales Rate',
        'Weight (%)',
        'Weighted (units/day)',
      ];
    }
  }

  roundFloatNumber(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  toCoverageRequireData(restockSuggestion: RestockSuggestion) {
    const orderIntervalDays =
      this.choosenItem?.orderInterval ??
      this.currentVendor?.orderInterval ??
      this.currentCompany?.orderInterval;

    const orderIntervalUnits = this.roundFloatNumber(
      orderIntervalDays * restockSuggestion.demand
    );

    const qtyAdditionalInventory = this.roundFloatNumber(
      restockSuggestion.additionalInventory * restockSuggestion.demand
    )

    const coverageRequiredField =
      this.salesVelocitySettingsType === 'purchasing'
        ? {
            field: 'orderInterval',
            displayName: CoverageRequired.orderInterval,
            days: orderIntervalDays || 0,
            units: orderIntervalUnits || 0,
            isTotal: false,
          }
        : {
            field: 'targetQty',
            displayName: CoverageRequired.targetQty,
            days: restockSuggestion.targetMaxDays || 0,
            units: restockSuggestion.targetQtyOnHand || 0,
            isTotal: false,
          };

    const additionalInventoryField = this.isPurchasing
      ? [
          {
            field: 'additionalInventory',
            displayName: CoverageRequired.additionalInventory,
            days: restockSuggestion.additionalInventory || 0,
            units: qtyAdditionalInventory || 0,
            isTotal: false,
          },
        ]
      : [];

    this.COVERAGE_REQUIRED_TABLE_DATA = {
      headers: ['Required Inventory', 'Days', 'Units'],
      data: [
        coverageRequiredField,
        {
          field: 'supplierLeadTime',
          displayName: CoverageRequired.supplierLeadTime,
          days: restockSuggestion.supplierLeadTime || 0,
          units: restockSuggestion.qtySupplierLeadTime || 0,
          isTotal: false,
        },
        {
          field: 'localLeadTime',
          displayName: CoverageRequired.localLeadTime,
          days: restockSuggestion.localLeadTimeDays || 0,
          units: restockSuggestion.qtyToLocalLeadTime || 0,
          isTotal: false,
        },
        ...additionalInventoryField,
        {
          field: 'total',
          displayName: CoverageRequired.total,
          days: 0,
          units: 0,
          isTotal: true,
        },
      ],
    };

    if (this.restockType === RestockType.Warehouse) {
      // Remove supplier lead time
      this.COVERAGE_REQUIRED_TABLE_DATA.data.splice(1, 1);
    } else {
      // Remove warehouse lead time
      this.COVERAGE_REQUIRED_TABLE_DATA.data.splice(2, 1);
    }
  }

  toCoverageAvailableData(restockSuggestion: RestockSuggestion) {
    this.COVERAGE_AVAILABLE_TABLE_DATA = {
      headers: ['Inventory Available', "Days' Worth", 'Units'],
      data: [
        {
          field: 'onHand',
          displayName: this.isCompanyTypeASC
            ? CoverageAvailable.onHand
            : CoverageAvailable.onHandAlt,
          days: restockSuggestion.amazonInventoryDays,
          units: restockSuggestion.currentAmazonInventory || 0,
          isTotal: false,
        },
        {
          field: 'local',
          displayName: CoverageAvailable.local,
          days: restockSuggestion.localInventoryDays,
          units:
            this.isPurchasing || this.currentCompany?.isUsingWarehouseInventory
              ? restockSuggestion.localQty || 0
              : 0,
          isTotal: false,
        },
        {
          field: 'awdInventory',
          displayName: CoverageAvailable.awdInventory,
          days: restockSuggestion.awdInventoryDays,
          units:
            this.isPurchasing || this.currentCompany?.isUsingWarehouseInventory
              ? restockSuggestion.awdInventoryQty || 0
              : 0,
          isTotal: false,
          isHidden: !this.currentCompany?.isUsingAWDInventoryQty,
        },
        {
          field: 'total',
          displayName: CoverageAvailable.total,
          days: 0,
          units: 0,
          isTotal: true,
        },
      ],
    };
  }

  checkNegativeNumber(number: number) {
    return number < 0;
  }

  getPositiveNumBer(number: number) {
    const convertNumber = Math.abs(number);
    return convertNumber % 1 !== 0 ? convertNumber.toFixed(2) : convertNumber;
  }

  isMany(number: number) {
    return number > 0 && number !== 1;
  }

  handleAdjSwitchClick(adjustSwitchType: AdjustSwitchType): void {
    switch (adjustSwitchType) {
      case AdjustSwitchType.adjustedDailySalesRate:
        this.isUsingAdjustedDailySalesRate =
          !this.isUsingAdjustedDailySalesRate;
        this.choosenItem.isUsingAdjustedDailySalesRateOption[
          this.salesVelocitySettingsType
        ] = this.isUsingAdjustedDailySalesRate;
        break;

      case AdjustSwitchType.autoAdjustForOutOfStock:
        this.isAdjustingDailySalesRateForOutOfStock =
          !this.isAdjustingDailySalesRateForOutOfStock;
        this.choosenItem.isAdjustingDailySalesRateForOutOfStockOption[
          this.salesVelocitySettingsType
        ] = this.isAdjustingDailySalesRateForOutOfStock;
        break;
    }

    this.isSaving = true;
    this.saveAdjSwitchForItem$.next(adjustSwitchType);
  }

  handleTotalComponentDataClick(event: MouseEvent) {
    (event.target as HTMLElement).blur();
    this.isTotalComponentDataOn = !this.isTotalComponentDataOn;

    this.reloadAllData();
  }

  getRestockSuggestionValue(field: string) {
    if (
      this.isTotalComponentDataOn ||
      _.isEmpty(this.restockSuggestion?.isolatedValues)
    ) {
      return this.restockSuggestion?.[field];
    }

    return this.restockSuggestion.isolatedValues[field];
  }

  private saveAdjSwitchForItem(adjustSwitchType: AdjustSwitchType) {
    return this.itemService.save(this.choosenItem, 'key').pipe(
      this.autoCleanUp(),
      switchMap(() =>
        this.restockSuggestionService.getByIdWithType(
          this.choosenItem?.key,
          this.salesVelocitySettingsType
        )
      ),
      tap(
        (rs) => {
          this.hasAdjustedToggleChanged = true;
          this.restockSuggestion = rs;
          const restockSuggestion =
            this.isTotalComponentDataOn ||
            _.isEmpty(this.restockSuggestion?.isolatedValues)
              ? this.restockSuggestion
              : {
                  ...this.restockSuggestion,
                  ...this.restockSuggestion.isolatedValues,
                };

          this.toSuggestedData(restockSuggestion);
          this.toVelocityData(restockSuggestion);
          this.toCoverageRequireData(restockSuggestion);
          this.toCoverageAvailableData(restockSuggestion);

          switch (adjustSwitchType) {
            case AdjustSwitchType.adjustedDailySalesRate:
              this.isUsingAdjustedDailySalesRate
                ? this.snotifyService.success(
                    'Average daily sales rate will be calculated using the ADJUSTED values'
                  )
                : this.snotifyService.success(
                    'Average daily sales rate will be calculated using the ORIGINAL values'
                  );
              break;

            case AdjustSwitchType.autoAdjustForOutOfStock:
              this.isAdjustingDailySalesRateForOutOfStock
                ? this.snotifyService.success(
                    "Daily sales rate's weight will be automatically distributed"
                  )
                : this.snotifyService.success(
                    "Daily sales rate's weight will remain unchanged"
                  );
          }

          this.isSaving = false;
        },
        () => {
          switch (adjustSwitchType) {
            case AdjustSwitchType.adjustedDailySalesRate:
              this.isUsingAdjustedDailySalesRate =
                !this.isUsingAdjustedDailySalesRate;
              break;
            case AdjustSwitchType.autoAdjustForOutOfStock:
              this.isAdjustingDailySalesRateForOutOfStock =
                !this.isAdjustingDailySalesRateForOutOfStock;
              break;
          }

          this.snotifyService.error(
            'Changing values used for calculation failed'
          );
          this.isSaving = false;
        }
      )
    );
  }

  reloadAllData() {
    this.isUsingAdjustedDailySalesRate =
      this.choosenItem?.isUsingAdjustedDailySalesRateOption?.[
        this.salesVelocitySettingsType
      ] !== null &&
      this.choosenItem?.isUsingAdjustedDailySalesRateOption?.[
        this.salesVelocitySettingsType
      ] !== undefined
        ? this.choosenItem?.isUsingAdjustedDailySalesRateOption?.[
            this.salesVelocitySettingsType
          ]
        : this.currentCompany?.isUsingAdjustedDailySalesRate;
    this.isAdjustingDailySalesRateForOutOfStock =
      this.getRestockSuggestionValue('isAdjustingDailySalesRateForOutOfStock');

    if (!this.isCompanyTypeASC || this.isTotalComponentDataOn) {
      this.isUsingAdjustedDailySalesRate = false;
      this.isAdjustingDailySalesRateForOutOfStock = false;
    }

    const rs =
      this.isTotalComponentDataOn ||
      _.isEmpty(this.restockSuggestion?.isolatedValues)
        ? this.restockSuggestion
        : {
            ...this.restockSuggestion,
            ...this.restockSuggestion.isolatedValues,
          };

    this.toSuggestedData(rs);
    this.toVelocityData(rs);
    this.toCoverageRequireData(rs);
    this.toCoverageAvailableData(rs);
  }

  private constructWeightedData(
    restockSuggestion: RestockSuggestion,
    settingData
  ) {
    return [2, 7, 14, 30, 60, 90, 180].reduce((a, cur) => {
      a[`weighted${cur}day`] = this.roundFloatNumber(
        ((conditionalOperator(
          this.isUsingAdjustedDailySalesRate,
          restockSuggestion[`adjSv${cur}d`],
          restockSuggestion[`sv${cur}d`]
        ) || 0) *
          (settingData[`percent${cur}Day`] || 0)) /
          100
      );
      return a;
    }, {});
  }

  private constructVelocityData(
    restockSuggestion: RestockSuggestion,
    settingData,
    weightedData
  ) {
    return [2, 7, 14, 30, 60, 90, 180].reduce((a, cur) => {
      const outOfStockField = `outOfStock${cur}d`;
      return a.concat({
        field: `${cur}day`,
        displayName: TimePeriod[`_${cur}_day`],
        outOfStockDays: ` (${restockSuggestion[outOfStockField] || 0})`,
        salesVelo: restockSuggestion[`sv${cur}d`] || 0,
        adjVelo: restockSuggestion[`adjSv${cur}d`] || 0,
        weight: (settingData[`percent${cur}Day`] || 0) + '%',
        weighted: weightedData[`weighted${cur}day`],
      });
    }, []);
  }
}
