export class PurchaseOrderItem {
  key: string = '';
  purchaseOrderKey: string = '';
  docType: string = '';
  orderKey: string = '';
  rowKey: string = '';
  itemName: string = '';
  asin: string = '';
  description: string = '';
  vendorPrice: number = 0;
  purchaseQty: number = 0;
  truePurchaseQty: number = 0;
  tags: string[] = [];
  fnsku: string = '';
  lotMultipleQty: number = 1;
  moq: number = 0;
  orderQty: number = 0;

  //hidden fields
  dueDate: Date = null;
  docDate: Date = null;
  refNum: string = '';
  openQty: number = 0;
  isChild: boolean = false;
  isParent: boolean = false;
  constructor(init?: Partial<PurchaseOrderItem>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
