export const PROCESSING_STEP_ORDER = [
  'company',
  'shipment',
  'vendor',
  'item',
  'bom',
  'demand',
  'supply',
  'forecast',
];

export enum PROCESSING_STEP_NAME {
  COMPANY_INFO = 'Company Info',
  RESTOCKAMZ_SETTING = 'RestockAMZ Setting',
  VENDORS = 'Suppliers',
  ITEMS = 'Items',
  KITS = 'Upload Kits',
  DEMAND = 'Demand',
  SUPPLY = 'Purchase Orders',
  FINISHED = 'Finished',
}
