export const OptionalDatas = {
  blank: 'Blank',
  sku: 'SKU',
  supplierSku: 'Supplier SKU',
  asin: 'ASIN',
  upc: 'UPC',
  ean: 'EAN',
  stickerText: 'Sticker Text',
  customText: 'Custom Text',
}

export const StickerFormats = {
  _27_1: '27-up labels 2-5/8 x 1" on US Letter',
  _30_66: '30-up labels 66.00 x 22.00 mm on US Letter',
  _24: '24-up labels 64.00 x 33.90 mm on A4',
  _50: '50.00 x 30.00 mm',
  customSize: 'Custom Size',
}

export const PrintOptions = {
  blank: 'Print a blank label between each SKU',
  continuously: 'Print labels continuously',
  own: 'Print each SKU on its own page',
}
