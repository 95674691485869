import { PurchaseOrderStatus } from '../infrastructure/enums/purchase-order-status';

export class PurchaseOrder {
  key: string = '';
  refNum: string = '';
  vendorKey: string = '';
  supplierKey: string = '';
  dueDate: Date;
  docDate: Date;
  isHidden: boolean = false;
  taxesFee: number = 0;
  shippingFee: number = 0;
  otherFee: number = 0;
  shipVia: string = '';
  fob: string = '';
  paymentTerms: string = '';
  comments: string = '';
  vendorName?: string;
  subTotal?: number;
  openQty?: number;
  orderQty?: number;
  status? = PurchaseOrderStatus.PENDING;
  shipTo?: string = null;
  totalCost?: number;
  stepProgress: {
    poItemSelection?: boolean;
    shipmentOptions?: boolean;
    poReview?: boolean;
    complete?: boolean;
  };
  notes: string = '';
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  shipmentId?: string;
  shipmentKey?: string;

  constructor(init?: Partial<PurchaseOrder>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
