import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
  AfterViewInit,
  ElementRef,
  HostListener,
  ViewEncapsulation,
  TemplateRef,
} from '@angular/core';
import {
  Observable,
  Subject,
  fromEvent,
  forkJoin,
  asapScheduler,
  asyncScheduler,
  BehaviorSubject,
  of,
  Subscription,
  zip,
} from 'rxjs';
import {
  map,
  tap,
  debounceTime,
  finalize,
  switchMap,
  first,
  catchError,
} from 'rxjs/operators';

import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import {
  ColumnReorderEvent,
  ColumnVisibilityChangeEvent,
  GridComponent,
  GridDataResult,
  RowArgs,
  SelectionEvent,
} from '@progress/kendo-angular-grid';

import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';

import _ from 'lodash';
import { toggleFullScreen } from 'src/app/theme/shared/full-screen/toggle-full-screen';
import { HeaderService } from 'src/app/core/services/header.service';
import {
  ItemField,
  RestockSuggestionItemField,
  RestockSuggestionSupplierField,
} from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { DisplayControlInput } from 'src/app/core/infrastructure/enums/display-control-input.enum';
import { Logic, Sort } from 'src/app/core/infrastructure/enums/logic.enum';
import { ResourceService } from 'src/app/core/services/resource-service';
import { formatDate } from '@angular/common';
import { FilterExpression } from '@progress/kendo-angular-filter';

import {
  ConditionOperator,
  FlagName,
  getFieldGroupTip,
  ItemStatus,
  ShipmentDropDown,
} from './field-group.constant';
import { DisplayControlOutput } from 'src/app/core/infrastructure/enums/display-control-output.enum';
import { Flag } from 'src/app/core/infrastructure/enums/flag.enum';
import { SurchargeQuantityField, SurchargePriceField } from 'src/app/core/infrastructure/enums/surcharge-field.enum';
import {
  amazonInventoryLink,
  amazonProductLink,
  getCurrencyCodeByMarketplaceId,
} from 'src/app/core/constants/amazon-link.constant';
import { InventoryAgeDisplayField, InventoryAgeDetail } from 'src/app/core/constants/inventory-age-field.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { head, isNumber } from 'underscore';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SaleVelocityCalculationComponent } from '../sale-velocity-calculation/sale-velocity-calculation.component';
import { RestockSuggestion } from 'src/app/core/models/restock-suggestion';
import { EstimatedMarginCalculationModalComponent } from '../estimated-margin-calculation-modal/estimated-margin-calculation-modal.component';
import { GenericItemModalComponent } from '../generic-item-modal/generic-item-modal.component';
import { MetadataService } from 'src/app/core/services/metadata.service';
import { ItemService } from 'src/app/core/services/item.service';
import { RESTOCK_MODELS_SETTINGS } from 'src/app/core/constants/restock-model.constant';
import { SnotifyService } from 'ng-snotify';
import { SyncService } from 'src/app/core/services/sync.service';
import { GridName } from '../forecast-rxdata-table/forcast-rxdata-table.constant';
import { HttpHeaders } from '@angular/common/http';
import { RestockSuggestionService } from 'src/app/core/services/restock-suggestion.service';
import { EditTagsModalComponent } from '../edit-tags-modal/edit-tags-modal.component';
import { CreateShipmentModalComponent } from '../../components/modal/create-shipment-modal/create-shipment-modal.component';
import { Shipment } from 'src/app/core/models/shipment';
import { ShipmentDetailService } from 'src/app/core/services/shipment-detail.service';
import { ShipmentDetail } from 'src/app/core/models/shipment-detail';
import { GridView, IGridViewChangeEvent } from 'src/app/core/models/grid-view';
import { DefaultRestockSuggestionSupplier } from 'src/app/routes/dashboard/shipments/restock-suggestions/restock-suggestions.constant';
import {
  conditionalOperator,
  getReadableKendoOperator,
  sortColumnsByField,
  transformBooleanToYesNo,
  getUnitFromText,
  sortMixTextNumber
} from '@stockaid/utils';
import { transformOversizeToYesNo } from '@stockaid/shared-utils';
import { RestockSuggestionSupplierService } from 'src/app/core/services/restock-suggestion-supplier.service';
import { RestockSuggestionSupplier } from 'src/app/core/models/restock-suggestion-supplier';
import { ShipmentService } from 'src/app/core/services/shipment.service';
import { ModalType } from 'src/app/core/infrastructure/enums/modal-type.enum';
import { InboundAnalyzerModalComponent } from 'src/app/theme/shared/forecastui/inbound-analyzer-modal/inbound-analyzer-modal.component';
import { ItemCardDetailComponent } from '../item-card-detail/item-card-detail.component';
import { MetaData } from 'src/app/core/infrastructure/classes/meta-data';
import { Item } from 'src/app/core/models/item';
import { CompanyService } from 'src/app/core/services/company.service';
import Swal from 'sweetalert2';
import { RestockType } from 'src/app/core/infrastructure/enums/restock-type.enum';
import { FILTER_WINDOW_SIZE } from 'src/app/core/constants/size-constant';
import { PusherService } from 'src/app/core/services/pusher.service';
import {
  NotificationKey,
  NotificationType,
} from 'src/app/core/infrastructure/enums/notification.enum';
import { GridState } from 'src/app/core/infrastructure/classes/grid-state';
import { Company } from 'src/app/core/models/company';
import { Currencies } from 'src/app/core/constants/currency.constant';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/models/user';
import { AuthenticationService, ModalService } from '@stockaid/services';
import { ImportFailModalComponent } from '../import-fail-modal/import-fail-modal.component';
import { FilterEditor } from '@progress/kendo-angular-filter/dist/es2015/model/filter-expression';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { COMMON_FORMAT_DATE } from 'src/app/core/constants/format-date.constant';
import { AdvancedFilter } from 'src/app/core/infrastructure/enums/advanced-filter.enum';

declare const CSVBoxImporter: any;

@Component({
  selector: 'app-field-group-data-table',
  templateUrl: './field-group-data-table.component.html',
  styleUrls: ['./field-group-data-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FieldGroupDataTableComponent<T>
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @HostListener('window:beforeunload')
  deleteShipment() {
    const currentShipmentKey = this.currentShipmentOption?.value;
    if (!currentShipmentKey || !this.isUpload) {
      return;
    }

    this.shipmentService
      .delete(currentShipmentKey)
      .pipe(catchError(() => of(null)))
      .subscribe();
  }

  @ViewChild(GridComponent) private grid: GridComponent;
  @ViewChild('gridView') gridView: ElementRef<HTMLElement>;
  @ViewChild('checkboxSelectAllTag') private checkboxSelectAllTag;
  @ViewChild('useReForecastCheckBox') useReForecastCheckBox;
  @ViewChild('useReSupplierCheckBox') useReSupplierCheckBox;
  @ViewChild('useReWarehouseCheckBox') useReWarehouseCheckBox;
  @ViewChild('useReExpeditedCheckBox') useReExpeditedCheckBox;
  @ViewChild('useReCasePackQtyCheckBox') useReCasePackQtyCheckBox;
  @ViewChild('filterSelectTemplate', { static: true })
  filterSelectTemplate: TemplateRef<any>;

  @Input() itemName: string;
  @Input() modelType: <T>() => T;
  @Input() groupMetaDataFields: MetaDataField[];
  @Input() resourceService: ResourceService<T>;
  @Input() itemsKeySelected: string[] = [];
  @Input() keyName = 'key';
  @Input() gridDescription: string;
  @Input() dateFieldsNeededFormatting: string[] = [];
  @Input() fieldsToLookUp: string[] = [];
  @Input() isViewOnly: boolean = false;
  @Input() unsortableColumns: string[] = [];
  @Input() initialGridState: State;
  @Input() isGridStateVisible: boolean = false;
  @Input() searchTerm: string;
  @Input() userSelectedGridView: GridView;
  @Input() userSelectedGridViewState: GridState;
  @Input() isSidebarCollapsed = false;
  @Input() isFullScreen = false;
  @Input() advancedFilter: AdvancedFilter | null=null;
  @Input() additionalReadOnlyCols: string[] = [];
  private _fieldGroups: string[][];
  @Input() set fieldGroups(value: string[][]) {
    const pendingGroupIndex = value.findIndex((group) => group.includes(RestockSuggestionItemField.pending));

    this._fieldGroups = value;

    if (!this.currentCompany?.isUsingAWDInventoryQty) {
      this._fieldGroups[pendingGroupIndex] = this._fieldGroups[pendingGroupIndex]?.filter((field) => field !== RestockSuggestionItemField.awdInventoryQty);
    }

    if (!this.metaDataFields?.length) {
      return;
    }

    this.metaDataFieldsGroup = this._fieldGroups.map((field) => {
      const metaDatas = this.metaDataFields
        .filter((item) => field?.includes(item.field))
        .sort((a, b) => field.indexOf(a.field) - field.indexOf(b.field));
      const title = metaDatas.map((field) => field.displayName).join(', ');

      //Because hide/show of column will depend on the first field of field group, so metaDatas[0] is first field
      const initialUpload = metaDatas[0]?.initialUpload || false;

      return { group: field, metaDatas, title, initialUpload };
    });
    this.metaDataFieldsGroup = sortColumnsByField(
      this.metaDataFieldsGroup,
      'initialUpload',
      'desc'
    );
  }
  get fieldGroups(): string[][] {
    return this._fieldGroups;
  }

  private _metaDataFields: MetaDataField[];
  @Input() set metaDataFields(value: MetaDataField[]) {
    this._metaDataFields = value;
    this.lockedColumns = value
      ?.filter((c) => c.locked)
      ?.map((c) => c.field);
  };
  get metaDataFields(): MetaDataField[] {
    return this._metaDataFields;
  }

  @Output() columnChange = new EventEmitter();
  @Output() reorderChange = new EventEmitter();
  @Output() stateChange = new EventEmitter();
  @Output() searchChange = new EventEmitter();
  @Output() gridViewChange = new EventEmitter();
  @Output() currentFbaShipment = new EventEmitter();
  @Output() columnResize = new EventEmitter();
  @Output() deleteNotificationType = new EventEmitter()
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (this.isFullScreen) {
      this.fullScreen();
      window.history.forward();
    }
  }

  shouldLoadGrid: Subject<any> = new Subject<any>();
  neededToBeHiddenElements: HTMLElement[] = [];
  metaDataFieldsGroup: any;
  gridItems: GridDataResult;
  decodedSupplierName: string = '';
  pageSizes = [
    { text: '20', value: 20 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
  ];
  gridState: State = {
    skip: 0,
    take: 20,
    sort: [],
    filter: {
      logic: Logic.and,
      filters: [],
    },
  };
  gridHeight: number;
  selectedRows: Map<string, T> = new Map();
  isDeleteDisabled = true;
  isSaveInProgress: boolean;
  originalValue: any;
  ICON_MAXIMIZE = 'assets/images/full-screen.svg';
  ICON_MINIMIZE = 'assets/images/minimize.svg';
  ICON_ASC = 'field-group-table__sort-icon--asc';
  ICON_DESC = 'field-group-table__sort-icon--desc';
  iconFullScreen: string = this.ICON_MAXIMIZE;
  DEFAULT_MINIMUM_GRID_HEIGHT = 430;
  isEmptyGrid: boolean = false;
  items: any;
  oldItems: any;
  searchFilters: CompositeFilterDescriptor[];
  isDisabledDelete: boolean = true;
  selectedTagRows: T[] = [];
  selectedKeyTag: any;
  columnVisibilityArgs: ColumnVisibilityChangeEvent;
  columnReorderArgs: ColumnReorderEvent;
  isSyncFile: boolean = false;
  isDisableSupplierFilter: boolean = false;
  isFilteringExpeditedQty: boolean = false;
  havingExpeditedItems: boolean = false;
  shipmentSearchSubject = new BehaviorSubject<string>(null);
  importer: any;
  isUpload = false;
  isImportFailModalOpen = false;
  isUploadSuccessful = false;
  reorderSubject$: Subject<any> = new Subject<any>();
  lockSubject$: Subject<any> = new Subject<any>();
  lockDelaySubscription$: Subscription;
  lockedColumns: string[] = [];
  isLockedByButton = false;
  isResettingLockedColumns = false;

  readonly FORMAT_DATE = COMMON_FORMAT_DATE
  readonly AdvancedFilter = AdvancedFilter
  readonly ModalType = ModalType;
  readonly GridName = GridName;
  readonly inputTypeEnum = DisplayControlInput;
  readonly RestockSuggestionItemField = RestockSuggestionItemField;
  readonly RESTOCK_MODELS_SETTINGS = RESTOCK_MODELS_SETTINGS;
  readonly DisplayControlOutput = DisplayControlOutput;
  readonly Flag = Flag;
  readonly ItemStatus = ItemStatus;
  readonly FlagName = FlagName;
  readonly ConditionOperator = ConditionOperator;
  readonly SurchargeQuantityField = Object.keys(SurchargeQuantityField);
  readonly SurchargePriceField = Object.keys(SurchargePriceField);
  readonly InventoryAgeField = Object.keys(InventoryAgeDisplayField);
  readonly InventoryAgeDisplayField = InventoryAgeDisplayField;
  readonly InventoryAgeDetail = InventoryAgeDetail;
  readonly transformBooleanToYesNo = transformBooleanToYesNo;
  readonly transformOversizeToYesNo = transformOversizeToYesNo;
  readonly excludedFilterableFields = [
    RestockSuggestionItemField.type,
    RestockSuggestionItemField.flag,
    RestockSuggestionItemField.status,
    RestockSuggestionItemField.imageUrl,
    RestockSuggestionItemField.supplier,
    RestockSuggestionItemField.prepGuide,
    RestockSuggestionItemField.sum,
    RestockSuggestionItemField.inboundTotal,
    RestockSuggestionItemField.useReForecastQty,
    RestockSuggestionItemField.useReSupplierQty,
    RestockSuggestionItemField.useReWarehouseQty,
    RestockSuggestionItemField.useReCasePackQty,
    RestockSuggestionItemField.useReForecastQtyByCasePackQty,
    RestockSuggestionItemField.useReSupplierQtyByCasePackQty,
    RestockSuggestionItemField.useReWarehouseQtyByCasePackQty,
    RestockSuggestionItemField.inventoryAges,
    RestockSuggestionItemField.estimatedAgedInventorySurcharges,
    RestockSuggestionItemField.maximumShipmentQty
  ];
  readonly FILTER_WINDOW_SIZE = FILTER_WINDOW_SIZE;
  readonly getUnitFromText = getUnitFromText;
  readonly RESTOCK_MODEL_SELECTIONS = Object.keys(RESTOCK_MODELS_SETTINGS).filter((key) => isNaN(Number(key))).map((key) => ({
    value: key,
    displayName: RESTOCK_MODELS_SETTINGS[key]
  }))

  currentItems: any;
  currentOperator = false;

  statusOptions: Array<{ text: ItemStatus; value: ItemStatus }> = [
    {
      text: ItemStatus.ACTIVE,
      value: ItemStatus.ACTIVE,
    },
    {
      text: ItemStatus.WATCH,
      value: ItemStatus.WATCH,
    },
    {
      text: ItemStatus.IGNORE,
      value: ItemStatus.IGNORE,
    },
  ];

  casePackOptions: Array<{ text: string; value: RestockSuggestionItemField }> =
    [
      {
        text: 'Use Forecast Recommended Qty',
        value: RestockSuggestionItemField.useReForecastQtyByCasePackQty,
      },
      {
        text: 'Use Supplier Restock Recommendation',
        value: RestockSuggestionItemField.useReSupplierQtyByCasePackQty,
      },
      {
        text: 'Use Warehouse Restock Recommendation',
        value: RestockSuggestionItemField.useReWarehouseQtyByCasePackQty,
      },
    ];

  flagOptions: Array<{ text: FlagName; value: Flag }> = [
    { text: FlagName.RED, value: Flag.RED },
    { text: FlagName.YELLOW, value: Flag.YELLOW },
    { text: FlagName.ORANGE, value: Flag.ORANGE },
    { text: FlagName.TEAL, value: Flag.TEAL },
    { text: FlagName.GREEN, value: Flag.GREEN },
  ];

  supplierOptions: Array<{ text: string; value: string }> = [];

  selectedItemKeys = [];
  shipmentsData: ShipmentDropDown[] = [];
  currentShipmentOption: ShipmentDropDown = null;
  shipmentDefault: ShipmentDropDown = {
    text: 'Select shipment...',
    value: null,
  };
  currentShipmentItems: ShipmentDetail[] = [];

  // Set default options of filter
  currentStatusFilter = null;
  currentFlagFilter = null;
  currentSupplierFilters = [];
  supplierFilter: CompositeFilterDescriptor;
  currentSort: SortDescriptor[] = [];

  // Extra filters
  filters: FilterExpression[] = [];
  opened = false;
  filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  defaultFilterValue: CompositeFilterDescriptor = {
    logic: Logic.and,
    filters: [],
  };
  filterWindowLeft: number;
  filterWindowTop: number;
  isFilteringLocalQty: boolean = false;
  isFilteringRecommendedQty: boolean = false;
  filtersLocalQty = {
    filters: [{ field: 'localQty', operator: 'gt', value: 0 }],
    logic: Logic.and,
  };
  filtersRecommendedQty: CompositeFilterDescriptor = {
    filters: [
      { field: 'forecastRecommendedQty', operator: 'gt', value: 0 },
      { field: 'suggReorder', operator: 'gt', value: 0 },
      { field: 'suggShip', operator: 'gt', value: 0 },
    ],
    logic: Logic.or,
  };
  isToolbarCollapsed = false;
  inventoryAgeCollapses = {
    inventoryAge0to90: true,
    inventoryAge181to330: true
  };
  currentInventoryAgeSortField = this.InventoryAgeField[0];
  currentPopup: NgbPopover;

  get currentCompany(): Company {
    return this.companyService.currentCompany();
  }

  get currentUser(): User {
    return this.authService.currentUser;
  }

  get displayCurrency() {
    const currencyCodeByMarketPlaceId = getCurrencyCodeByMarketplaceId(
      this.currentCompany?.marketplaceId
    );
    const currencyCodeSetting = this.currentCompany?.currencyCode;
    const code = currencyCodeSetting || currencyCodeByMarketPlaceId || 'USD';
    const currency = Currencies.find((currency) => currency.code === code);
    return { code: currency.code, symbol: currency.symbol };
  }

  get isDefaultLockVisible(): boolean {
    if (this.lockedColumns?.length > 1) {
      return true;
    }

    const [lockedColumn] = this.lockedColumns || [];
    return lockedColumn !== RestockSuggestionItemField.sku;
  }

  constructor(
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private altModalService: ModalService,
    private metadataService: MetadataService,
    private itemService: ItemService,
    private restockSuggestionService: RestockSuggestionService,
    private snotifyService: SnotifyService,
    private syncService: SyncService,
    private shipmentDetailService: ShipmentDetailService,
    private restockSuggestionSupplierService: RestockSuggestionSupplierService,
    private shipmentService: ShipmentService,
    private companyService: CompanyService,
    private pusherService: PusherService,
    private authService: AuthenticationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.iconFullScreen = this.isFullScreen ? this.ICON_MINIMIZE : this.ICON_MAXIMIZE;
    this.loadCsvBoxImporter();
    this.loadExtraFilters();

    this.gridState = this.initialGridState
      ? { ...this.initialGridState }
      : { ...this.gridState };

    if (this.InventoryAgeField.includes(this.gridState?.sort?.[0]?.field)) {
      this.currentInventoryAgeSortField = this.gridState?.sort?.[0]?.field;
    }

    if (this.gridState.take >= 50) {
      this.gridState.take = 20;
    }
    this.currentSort = this.initialGridState.sort || [];
    if (this.itemName === GridName.RestockSuggestion) {
      const supplierName = this.route.snapshot.queryParamMap.get(
        RestockSuggestionSupplierField.vendorName
      );

      this.decodedSupplierName = decodeURIComponent(supplierName);

      this.loadAdditionalFilters(this.gridState);

      this.getSuppliers()
        .pipe(
          this.autoCleanUp(),
          switchMap(() => this.getRestockShipment())
        )
        .subscribe();
    }

    this.metaDataFieldsGroup = this.fieldGroups.map((field) => {
      const metaDatas = this.metaDataFields
        .filter((item) => field?.includes(item.field))
        .sort((a, b) => field.indexOf(a.field) - field.indexOf(b.field));
      const title = metaDatas.map((field) => field.displayName).join(', ');

      //Because hide/show of column will depend on the first field of field group, so metaDatas[0] is first field
      const initialUpload = metaDatas[0]?.initialUpload || false;
      return { group: field, metaDatas, title, initialUpload };
    });

    this.metaDataFieldsGroup = sortColumnsByField(
      this.metaDataFieldsGroup,
      'initialUpload',
      'desc'
    );
    this.onStateChange(this.gridState, false);

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(250),
        tap(() => this.calcGridHeight()),
        tap(() => this.calcFilterWindowPosition())
      )
      .subscribe();

    this.headerService.navbarCollapseSubject$
      .pipe(
        this.autoCleanUp(),
        tap((value) => {
          if (!value) {
            return;
          }

          this.isSidebarCollapsed = !this.isSidebarCollapsed;
        })
      )
      .subscribe();

    this.headerService.isFullScreen
      .pipe(
        this.autoCleanUp(),
        tap((isFullScreen) => {
          this.isFullScreen = isFullScreen;
          this.iconFullScreen = this.isFullScreen ? this.ICON_MINIMIZE : this.ICON_MAXIMIZE;
        })
      )

    this.pusherService.userChannel.bind('notification', (data) => {
      if (
        [NotificationType.notice, NotificationType.close].includes(data.type) &&
        [NotificationKey.summaryStatus, NotificationKey.mwsSyncStart].includes(
          data.key
        )
      ) {
        this.loadGridItems();
      }

      if (
        [NotificationKey.csvStart, NotificationKey.csvStatus].includes(data.key)
      ) {
        this.processFileNotification(data);
      }
    });

    zip(
      this.reorderSubject$,
      this.lockSubject$
    )
      .pipe(
        this.autoCleanUp(),
        tap(([reorderEvent, lockEvent]) => {
          const [lockingColumn] = lockEvent?.columns || [];

          if (this.isResettingLockedColumns) {
            this.lockedColumns = [];
          }
          const isLocked = this.checkColumnLockedStatus(lockingColumn);
          if (!isLocked) {
            reorderEvent.newIndex++;
          }

          this.reorderChange.emit({...reorderEvent, isLocked, isResettingLockedColumns: this.isResettingLockedColumns});
          this.columnReorderArgs = {...reorderEvent, isLocked, isResettingLockedColumns: this.isResettingLockedColumns};
          this.isResettingLockedColumns = false;
        })
      )
      .subscribe();

    this.checkHavingExpeditedItems();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    const currentShipmentKey = this.currentShipmentOption?.value;
    if (!currentShipmentKey || !this.isUpload) {
      return;
    }

    this.shipmentService
      .delete(currentShipmentKey)
      .pipe(catchError(() => of(null)))
      .subscribe();
  }

  ngAfterViewInit(): void {
    asapScheduler.schedule(() => {
      if (this.grid) {
        this.grid.loading = true;
        this.calcGridHeight();
      }
    });

    this.calcFilterWindowPosition();
  }

  shouldFieldDisplayed(field: RestockSuggestionItemField): boolean {
    const forbiddenFields = [];
    if (this.currentCompany?.companyType !== CompanyType.ASC || !this.currentCompany?.isUsingAWDInventoryQty) {
      forbiddenFields.push(RestockSuggestionItemField.inboundAwd);
    }

    return !forbiddenFields.includes(field);
  }

  calcFilterWindowPosition(): void {
    const navBarElement = document.querySelector('.pcoded-navbar');
    const headerElement = document.querySelector('.pcoded-header');

    this.filterWindowLeft =
      (window.innerWidth - navBarElement.getBoundingClientRect().right) / 2 -
      FILTER_WINDOW_SIZE.WIDTH / 2;
    this.filterWindowTop =
      (window.innerHeight - headerElement.getBoundingClientRect().bottom) / 2 -
      FILTER_WINDOW_SIZE.HEIGHT / 2;
  }

  handleDoNotRestockCheckboxClick(e, dataItem) {
    this.itemService
      .getById(dataItem.key)
      .pipe(
        this.autoCleanUp(),
        switchMap((item) => {
          item.doNotRestock = e.target.checked;
          item.isHidden = item.doNotRestock && item.doNotOrder;
          return this.itemService.save(
            {
              ...item,
            },
            'key'
          );
        })
      )
      .subscribe((res) => {
        this.grid.loading = false;
        Object.assign(dataItem, res);

        const name = dataItem['name'];
        this.snotifyService.success(`${name} updated successfully.`);
        this.loadGridItems();
      });
  }

  handleShipmentsFilter(value) {
    this.shipmentSearchSubject.next(value);
  }

  onShipmentChange(option) {
    this.currentFbaShipment.emit(option);
    if (option?.value) {
      this.isDisableSupplierFilter = true;
      this.resetSupplierFilter(option?.vendorName);
    } else {
      this.isDisableSupplierFilter = false;
      this.selectedItemKeys = [];
      this.currentShipmentItems = [];
      this.resetSupplierFilter(this.decodedSupplierName);
    }

    this.onStateChange(this.gridState, false);
  }

  getRestockShipment() {
    return this.shipmentSearchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm) =>
        this.shipmentService.getRsShipments(searchTerm)
      ),
      tap((shipments) => {
        this.shipmentsData =
          shipments?.length > 0
            ? shipments.map((shipment) => {
                return {
                  text: shipment.shipmentName,
                  value: shipment.key,
                  vendorName: shipment.vendorName,
                };
              })
            : [];
      })
    );
  }

  getSuppliers(): Observable<RestockSuggestionSupplier[]> {
    return this.restockSuggestionSupplierService.getAll().pipe(
      this.autoCleanUp(),
      tap(
        (items: RestockSuggestionSupplier[]) =>
          (this.supplierOptions = items.map((item) => {
            return { text: item.vendorName, value: item.vendorName };
          }))
      )
    );
  }

  getSupplierFilter(
    supplierName: string
  ): (CompositeFilterDescriptor | FilterDescriptor)[] {
    if (
      ![
        DefaultRestockSuggestionSupplier.AllSuppliers,
        DefaultRestockSuggestionSupplier.Unassigned,
      ].includes(supplierName as DefaultRestockSuggestionSupplier)
    ) {
      return [
        supplierName === DefaultRestockSuggestionSupplier.MyWarehouse
          ? {
              field: RestockSuggestionItemField.localQty,
              operator: 'gt',
              value: 0,
            }
          : {
              field: supplierName
                ? RestockSuggestionItemField.supplier
                : 'vendorKey',
              operator: 'eq',
              value: supplierName || null,
            },
      ];
    }

    if (supplierName === DefaultRestockSuggestionSupplier.Unassigned) {
      return [
        {
          field: 'vendorKey',
          operator: 'isnull',
          value: null,
        },
      ];
    }

    return [
      {
        field: RestockSuggestionItemField.supplier,
        operator: 'eq',
        value: supplierName,
      },
    ];
  }

  loadAdditionalFilters(state: State): void {
    this.isFilteringLocalQty = false;
    this.isFilteringRecommendedQty = false;
    this.currentFlagFilter = null;
    this.currentStatusFilter = null;
    this.searchFilters = [];
    for (let f of state.filter?.filters as CompositeFilterDescriptor[]) {
      const [firstFilter] = f.filters || [];
      if (
        (firstFilter as FilterDescriptor)?.value === this.searchTerm &&
        this.fieldsToLookUp.includes(
          (firstFilter as FilterDescriptor)?.field as string
        )
      ) {
        this.searchFilters = [f];
        break;
      }
    }

    this.loadDeepFilter(state);

    const extraFilters = state?.filter?.filters.find(
      (cf: CompositeFilterDescriptor) =>
        cf.filters.length &&
        cf.filters.every((f: FilterDescriptor) =>
          f.field &&
          !this.excludedFilterableFields.includes(
            f.field as RestockSuggestionItemField
          )
        )
    ) as CompositeFilterDescriptor;
    this.filterValue = extraFilters || {
      logic: Logic.and,
      filters: [],
    };

    if (state?.filter?.filters?.length) {
      state?.filter?.filters.forEach((cf) => {
        this.currentSupplierFilters = cf['currentSupplierFilters']
          ? []
          : this.currentSupplierFilters;
        cf['currentSupplierFilters']?.forEach((currentSupplierFilter) => {
          this.currentSupplierFilters.push(currentSupplierFilter);
        });
      });
    }

    if (this.decodedSupplierName) {
      this.currentSupplierFilters = [
        { text: this.decodedSupplierName, value: this.decodedSupplierName },
      ];
    }
  }

  applyFilters(state: State): void {
    let statusFilters = [];
    let flagFilters = [];
    let supplierFilters = [];
    const expeditedFilter =
      this.havingExpeditedItems && this.isFilteringExpeditedQty
        ? [
            {
              filters: [
                {
                  field: RestockSuggestionItemField.expeditedRecommendationQty,
                  operator: 'gt',
                  value: 0,
                },
              ],
              logic: Logic.and,
            },
          ]
        : [];

    this.currentStatusFilter?.forEach((filter) => {
      statusFilters.push({
        field: RestockSuggestionItemField.status,
        operator: 'eq',
        value: filter.value,
      });
    });

    this.currentFlagFilter?.forEach((filter) => {
      flagFilters.push({
        field: RestockSuggestionItemField.flag,
        operator: 'eq',
        value: filter.value,
      });
    });

    for (let filter of this.currentSupplierFilters) {
      if (filter.value === DefaultRestockSuggestionSupplier.AllSuppliers) {
        supplierFilters = [];
        break;
      }

      if (
        this.currentSupplierFilters?.length === 1 &&
        filter.value === this.decodedSupplierName
      ) {
        supplierFilters = this.getSupplierFilter(this.decodedSupplierName);
      }

      supplierFilters = supplierFilters.concat(
        this.getSupplierFilter(filter?.value)
      );
    }

    this.supplierFilter = conditionalOperator(
      Boolean(supplierFilters?.length),
      {
        logic: Logic.or,
        filters: supplierFilters,
      },
      null
    );

    const noLocalQtyFilter =
      !this.currentSupplierFilters?.length ||
      this.currentSupplierFilters
        ?.map((filter) => filter.value)
        ?.some((filterValue) =>
          [
            DefaultRestockSuggestionSupplier.AllSuppliers,
            DefaultRestockSuggestionSupplier.MyWarehouse,
          ].includes(filterValue)
        )
        ? []
        : [
            {
              logic: Logic.or,
              filters: [
                {
                  field: RestockSuggestionItemField.localQty,
                  operator: 'eq',
                  value: 0,
                },
                {
                  field: RestockSuggestionItemField.localQty,
                  operator: 'isnull',
                  value: null,
                },
              ],
            },
          ];

    state.filter = {
      logic: Logic.and,
      filters: [],
    };

    const localQtyFilter = conditionalOperator(
      this.isFilteringLocalQty,
      [this.filtersLocalQty],
      []
    );

    const recommendationQtyFilter = this.isFilteringRecommendedQty
      ? [this.filtersRecommendedQty]
      : [];

    state.filter.filters = [
      {
        logic: this.currentOperator ? Logic.and : Logic.or,
        filters: [
          {
            filters: flagFilters,
            logic: Logic.or,
          },
          {
            filters: statusFilters,
            logic: Logic.or,
          },
        ],
        currentSupplierFilters: this.currentSupplierFilters,
      },
    ]
      .concat(this.searchFilters || ([] as any))
      .concat(this.supplierFilter || ([] as any))
      .concat(this.filterValue as any)
      .concat(noLocalQtyFilter as any)
      .concat(localQtyFilter)
      .concat(recommendationQtyFilter as any)
      .concat(expeditedFilter as any);
  }

  onStateChange(state: State, willSaveState: boolean = true): void {
    this.applyFilters(state);
    state.sort = this.currentSort;

    state.sort = state.sort.filter((sorter) => sorter.dir);

    if (!state.filter) {
      state.filter = {
        logic: Logic.and,
        filters: [],
      };
    }

    this.gridState = { ...state };

    if (willSaveState) {
      this.stateChange.emit(this.gridState);
    }
    this.loadGridItems();
  }

  getDisplayName(field: string): string {
    return (
      this.metaDataFields?.find((metadataField) => {
        return field === metadataField.field;
      })?.displayName || _.startCase(field)
    );
  }

  getTooltip(field: string): string {
    return getFieldGroupTip(field);
  }

  onSelectionChange(args: SelectionEvent): void {
    args.deselectedRows.forEach((r: RowArgs) => {});

    args.selectedRows.forEach((r: RowArgs) => {});

    this.isDeleteDisabled = this.selectedRows.size === 0;
  }

  calcGridHeight() {
    const gridEl = this.grid?.wrapper?.nativeElement as HTMLElement;
    const { top } = gridEl?.getBoundingClientRect() || {};

    const calculatedHeight = window.innerHeight - top - 25;

    this.gridHeight = this.isFullScreen
      ? window.innerHeight - top + 20
      : Math.max(calculatedHeight, this.DEFAULT_MINIMUM_GRID_HEIGHT);
  }

  setDefaultMinimumGridHeight() {
    this.gridHeight = this.DEFAULT_MINIMUM_GRID_HEIGHT;
  }

  fullScreen(): void {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen && this.isSidebarCollapsed) {
      this.headerService.setNavbarCollapse(true);
    }

    toggleFullScreen(
      this.gridView.nativeElement,
      this.neededToBeHiddenElements,
      () => {
        this.headerService.isFullScreen.next(this.isFullScreen);
        this.iconFullScreen = this.isFullScreen ? this.ICON_MINIMIZE : this.ICON_MAXIMIZE;
        this.calcGridHeight();
      },
      this.isFullScreen
    );
  }

  getSortIconClass(field: string) {
    let finalField = field;
    if (field === RestockSuggestionItemField.inventoryAges) {
      finalField = this.currentInventoryAgeSortField;
    }

    const dir = this.gridState.sort?.find((s) => s.field === finalField)?.dir;

    switch (dir) {
      case Sort.asc:
        return this.ICON_ASC;

      case Sort.desc:
        return this.ICON_DESC;

      default:
        return '';
    }
  }

  sortField(field: string) {
    let finalField = field;
    if (field === RestockSuggestionItemField.inventoryAges) {
      finalField = this.currentInventoryAgeSortField;
    }

    const currentDir = this.gridState.sort?.find((s) => s.field === finalField)?.dir;

    let dir;
    switch (currentDir) {
      case Sort.asc:
        dir = Sort.desc;
        break;

      case Sort.desc:
        dir = '';
        break;

      default:
        dir = Sort.asc;
    }

    this.currentSort = dir
      ? [
          {
            field: finalField,
            dir,
          },
        ]
      : [];
    this.onStateChange(this.gridState);
  }

  sortInventoryAgeField(inventoryAgeSortField: string) {
    this.currentInventoryAgeSortField = inventoryAgeSortField;
    this.sortField(RestockSuggestionItemField.inventoryAges);
  }

  private checkHavingExpeditedItems() {
    let statusFilters = [];
    let flagFilters = [];
    let supplierFilters = [];
    const expeditedFilter = [
      {
        filters: [
          {
            field: RestockSuggestionItemField.expeditedRecommendationQty,
            operator: 'gt',
            value: 0,
          },
        ],
        logic: Logic.and,
      },
    ];

    this.currentStatusFilter?.forEach((filter) => {
      statusFilters.push({
        field: RestockSuggestionItemField.status,
        operator: 'eq',
        value: filter.value,
      });
    });

    this.currentFlagFilter?.forEach((filter) => {
      flagFilters.push({
        field: RestockSuggestionItemField.flag,
        operator: 'eq',
        value: filter.value,
      });
    });

    for (let filter of this.currentSupplierFilters) {
      if (filter.value === DefaultRestockSuggestionSupplier.AllSuppliers) {
        supplierFilters = [];
        break;
      }

      if (
        this.currentSupplierFilters?.length === 1 &&
        filter.value === this.decodedSupplierName
      ) {
        supplierFilters = this.getSupplierFilter(this.decodedSupplierName);
      }

      supplierFilters = supplierFilters.concat(
        this.getSupplierFilter(filter?.value)
      );
    }

    this.supplierFilter = supplierFilters?.length
      ? {
          logic: Logic.or,
          filters: supplierFilters,
        }
      : null;

    const noLocalQtyFilter =
      !this.currentSupplierFilters?.length ||
      this.currentSupplierFilters
        ?.map((filter) => filter.value)
        ?.some((filterValue) =>
          [
            DefaultRestockSuggestionSupplier.AllSuppliers,
            DefaultRestockSuggestionSupplier.MyWarehouse,
          ].includes(filterValue)
        )
        ? []
        : [
            {
              logic: Logic.or,
              filters: [
                {
                  field: RestockSuggestionItemField.localQty,
                  operator: 'eq',
                  value: 0,
                },
                {
                  field: RestockSuggestionItemField.localQty,
                  operator: 'isnull',
                  value: null,
                },
              ],
            },
          ];

    const state = { ...this.gridState };
    state.filter = {
      logic: Logic.and,
      filters: [],
    };

    state.filter.filters = [
      {
        logic: this.currentOperator ? Logic.and : Logic.or,
        filters: [
          {
            filters: flagFilters,
            logic: Logic.or,
          },
          {
            filters: statusFilters,
            logic: Logic.or,
          },
        ],
        currentSupplierFilters: this.currentSupplierFilters,
      },
    ]
      .concat(this.searchFilters || ([] as any))
      .concat(this.supplierFilter || ([] as any))
      .concat(this.filterValue as any)
      .concat(noLocalQtyFilter as any)
      .concat(expeditedFilter as any);
    this.resourceService
      .getCount(state.filter, '', this.currentCompany?.companyKey)
      .pipe(this.autoCleanUp())
      .subscribe((count) => {
        this.havingExpeditedItems = false;
        if (count > 0) {
          this.havingExpeditedItems = true;
        }
      });
  }

  private loadGridItems(): void {
    if (this.grid) {
      asapScheduler.schedule(() => (this.grid.loading = true));
    }
    let items$ = this.getItemFromResource();
    let count$ = this.resourceService.getCount(
      this.gridState.filter,
      '',
      this.currentCompany?.companyKey,
      [],
      this.currentCompany?.marketplaceId,
      this.currentCompany?.currencyCode
    );

    if (this.advancedFilter) {
      this.loadGridItemsWithAdvancedFilter();
      return;
    }

    forkJoin([items$, count$])
      .pipe(
        this.autoCleanUp(),
        finalize(() =>
          asapScheduler.schedule(() => (this.grid.loading = false))
        )
      )
      .subscribe(([items, count]) => {
        if (this.itemName === GridName.RestockSuggestion) {
          items = this.transformData(items);
        }

        this.currentItems = items;

        this.selectedTagRows = [];
        this.selectedKeyTag = {};

        if (this.checkboxSelectAllTag?.nativeElement?.checked) {
          this.selectedTagRows = items;
          this.selectedKeyTag = _.groupBy(this.selectedTagRows, 'key');
        }

        this.selectedItemKeys = items
          .filter((i) => i.restockKey)
          .map((i) => i.key);
        this.gridItems = { total: count, data: items };
        this.isEmptyGrid = count === 0;
      });
  }

  private getFilterEditor(dataType: string): FilterEditor {
    switch (dataType) {
      case 'STRING':
      case 'TEXT':
      case 'JSON':
        return 'string';

      case 'BOOLEAN':
        return 'boolean';

      case 'DATE':
        return 'date';

      default:
        return 'number';
    }
  }

  private loadExtraFilters(): void {
    this.metaDataFields
      .filter((m) =>
        !this.excludedFilterableFields.includes(
          m.field as RestockSuggestionItemField
        )
      )
      .concat([
        {
          field: 'inventoryAge0to60',
          displayName: 'Inventory age 0-60',
        },
        {
          field: 'inventoryAge61to90',
          displayName: 'Inventory age 61-90',
        },
        {
          field: 'inventoryAge91to180',
          displayName: 'Inventory age 91-180',
        },
        {
          field: 'inventoryAge181to330',
          displayName: 'Inventory age 181-330',
        },
        {
          field: 'inventoryAge331to365',
          displayName: 'Inventory age 331-365',
        },
        {
          field: 'inventoryAge365plus',
          displayName: 'Inventory age 365+',
        },
      ].map((f) => ({
        field: f.field,
        displayName: f.displayName,
        dataType: 'INTEGER',
        initialUpload: true,
        required: true,
        displayControl: null
      })))
      .sort((a, b) => sortMixTextNumber(a.displayName, b.displayName))
      .forEach((m) => {
        this.filters.push({
          field: m.field,
          title: m.displayName,
          editor: m.field === RestockSuggestionItemField.oversized ? 'boolean' : this.getFilterEditor(m.dataType)
        });
      });
  }

  getItemFromResource() {
    return this.resourceService
      .getFilteredUserTelerikEvent(
        this.gridState.skip,
        this.gridState.take,
        this.gridState.filter,
        this.gridState.sort,
        '',
        [],
        this.currentShipmentOption?.value,
        this.currentCompany?.marketplaceId,
        this.currentCompany?.currencyCode
      )
      .pipe(
        this.autoCleanUp(),
        tap((items) => (this.items = items)),
        map((items) =>
          items?.map((item: any) => {
            item = this.formatValueOfItem(item);

            return item;
          })
        )
      );
  }

  transformData(items) {
    items = items?.map((item: RestockSuggestion) => {
      item.onOrder = item.onOrder >= 0 ? item.onOrder : 0;
      item.restockNeeded = item.restockNeeded >= 0 ? item.restockNeeded : 0;
      item.suggReorder = item.suggReorder >= 0 ? item.suggReorder : 0;
      item.suggShip = item.suggShip >= 0 ? item.suggShip : 0;

      const defaultObject = new RestockSuggestion();
      Object.keys(item).forEach((key) => {
        if (!item[key] && isNumber(defaultObject[key])) {
          item[key] = defaultObject[key] + '';
        }
      });

      return item;
    });

    return items;
  }

  public downloadFile(): void {
    this.isSyncFile = true;
    const excludedFields: string[] = [
      RestockSuggestionItemField.imageUrl,
      RestockSuggestionItemField.type,
    ];

    const columnInitial = this.fieldGroups.map((field) => {
      const metaDatas = this.metaDataFields
        .filter((item) => field?.includes(item.field))
        .sort((a, b) => field.indexOf(a.field) - field.indexOf(b.field));
      //Because hide/show of column will depend on the first field of field group, so metaDatas[0] is first field
      const initialUpload = metaDatas[0]?.initialUpload || false;
      return { group: field, initialUpload };
    });

    let columns = columnInitial.reduce((total, curr) => {
      return curr.initialUpload ? [...total, ...curr.group] : total;
    }, [] as string[]);

    columns = columns.filter(
      (column: string) => !excludedFields.includes(column)
    );

    const sort = this.gridState?.sort;
    const filter = this.gridState?.filter;

    let itemName = this.itemName;

    if (itemName === GridName.RestockSuggestion) {
      itemName = 'restock-suggestion';
    }

    const notification = new BehaviorSubject<any>(0);
    const downloadSnotify = this.snotifyService.async(
      'Start download file',
      notification
    );
    setTimeout(() => {
      notification.next({ body: 'Downloading.....' });
    }, 1000);
    this.snotifyService.info(
      'You can do another action when generating file!',
      { timeout: 5000 }
    );
    const payload = {
      itemName: itemName,
      fields: columns,
      headersOnly: false,
      sortAttributes: sort,
      whereClause: filter,
      currencyCode: this.displayCurrency.code,
      marketPlaceId: this.currentCompany.marketplaceId,
      summaryOnHandType: this.currentCompany.summaryOnHandType,
    };
    this.syncService
      .downloadFile(payload, undefined, undefined, [], this.advancedFilter)
      .subscribe(
        (response) => {
          if (response?.status === 202) {
            this.handleDownloadLargeFile(notification);
            return;
          }

          notification.next({
            title: 'Success',
            body: 'File downloaded!',
            config: {
              closeOnClick: true,
              timeout: 5000,
              showProgressBar: true,
              type: 'success',
            },
          });
          notification.complete();
          this.syncService.removeDownloadingItem(this.itemName);
          this.isSyncFile = false;
          const headers: HttpHeaders = response.headers;

          const disposition: string = headers.get('Content-Disposition') || '';
          const matches = /filename=([^;]+)/gi.exec(disposition);
          const fileName = (matches[1] || 'untitled')
            .replace('"', '')
            .replace('"', '')
            .trim();

          const a = document.createElement('a');
          const blob = response.body;
          const url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = `${fileName}`;
          a.click();
          window.URL.revokeObjectURL(url);
        },
        () => {
          notification.complete();
          this.snotifyService.remove(downloadSnotify.id, true);
          this.syncService.removeDownloadingItem(this.itemName);
          this.isSyncFile = false;
        }
      );
  }

  formatValueOfItem(item): RestockSuggestion {
    const fieldOfNumberTypes = [
      RestockSuggestionItemField.supplierCost,
      RestockSuggestionItemField.supplierRebate,
      RestockSuggestionItemField.inboundShippingCost,
      RestockSuggestionItemField.reshippingCost,
      RestockSuggestionItemField.repackagingMaterialCost,
      RestockSuggestionItemField.repackingLaborCost,
      RestockSuggestionItemField.fba,
      RestockSuggestionItemField.lowestFba,
      RestockSuggestionItemField.nonFba,
      RestockSuggestionItemField.lowestNonFba,
      RestockSuggestionItemField.packageWeight,
      RestockSuggestionItemField.dimensionalWeight,
      RestockSuggestionItemField.casePackQuantity,
      RestockSuggestionItemField.inbound,
      RestockSuggestionItemField.inboundFcTransfer,
      RestockSuggestionItemField.sum,
      RestockSuggestionItemField.inboundWorking,
      RestockSuggestionItemField.inboundShipped,
      RestockSuggestionItemField.inboundReceiving,
      RestockSuggestionItemField.inboundTotal,
      RestockSuggestionItemField.targetDays,
      RestockSuggestionItemField.remaining,
      RestockSuggestionItemField.demand,
      RestockSuggestionItemField.outOfStockPercentage,
      RestockSuggestionItemField.reserved,
      RestockSuggestionItemField.unfulfillable,
      RestockSuggestionItemField.pending,
      RestockSuggestionItemField.localQty,
      RestockSuggestionItemField.maximumShipmentQty,
      RestockSuggestionItemField.suggShip,
      RestockSuggestionItemField.suggReorder,
      RestockSuggestionItemField.onOrder,
      RestockSuggestionItemField.restockNeeded,
      RestockSuggestionItemField.rank,
      RestockSuggestionItemField.referralFee,
      RestockSuggestionItemField.fbaFee,
      RestockSuggestionItemField.orderQty,
    ];
    Object.keys(item).forEach((key) => {
      if (key === RestockSuggestionItemField.remaining) {
        if (item.remaining !== 'Infinite') {
          item[key] = Math.floor(item[key]) + 'd';
        }
      }

      item[key] = isNumber(item[key]) ? String(item[key]) : item[key];

      if (
        !item[key] &&
        fieldOfNumberTypes.includes(key as RestockSuggestionItemField)
      ) {
        item[key] = '';
      }
    });

    return item;
  }

  capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  getFlagClass(flag: Flag): string {
    let flagClass =
      'fa fa-flag field-group-table__flag-icon field-group-table__flag-icon';

    switch (flag) {
      case Flag.ORANGE:
        flagClass += '--restock-now';
        break;

      case Flag.YELLOW:
        flagClass += '--restock-soon';
        break;

      case Flag.RED:
        flagClass += '--out-of-stock';
        break;

      case Flag.TEAL:
        flagClass += '--new';
        break;

      case Flag.GREEN:
        flagClass += '--well-stocked';
        break;
    }

    return flagClass;
  }

  getStatusIcon(status: ItemStatus): string {
    switch (status) {
      case ItemStatus.ACTIVE:
        return 'fa fa-check-circle';

      case ItemStatus.WATCH:
        return 'fa fa-eye';

      case ItemStatus.IGNORE:
        return 'fa fa-thumbs-down';

      case ItemStatus.INACTIVE:
        return 'fa fa-ban';
    }
  }

  getWidthColumn(metaDataField): number {
    switch (metaDataField.field) {
      case RestockSuggestionItemField.imageUrl:
        return metaDataField.width || 85;
      case RestockSuggestionItemField.flag:
        return metaDataField.width || 140;
      case RestockSuggestionItemField.hazmat:
      case RestockSuggestionItemField.category:
        return metaDataField.width || 130;
      case RestockSuggestionItemField.packageWeight:
      case RestockSuggestionItemField.prepGuide:
        return metaDataField.width || 200;
      case RestockSuggestionItemField.sku:
      case RestockSuggestionItemField.targetDays:
      case RestockSuggestionItemField.tags:
        return metaDataField.width || 210;

      case RestockSuggestionItemField.pending:
      case RestockSuggestionItemField.inboundShippingCost:
      case RestockSuggestionItemField.restockModel:
      case RestockSuggestionItemField.boxLength:
        return metaDataField.width || 250;
      case RestockSuggestionItemField.forecastRecommendedQty:
      case RestockSuggestionItemField.itemVolume:
        return metaDataField.width || 300;

      case RestockSuggestionItemField.orderQty:
      case RestockSuggestionItemField.estimatedExcessUnits:
        return metaDataField.width || 350;

      case RestockSuggestionItemField.casePackForecastRecommendedQty:
        return metaDataField.width || 400;

      default:
        return metaDataField.width || 180;
    }
  }

  onVisibilityChange(e: ColumnVisibilityChangeEvent): void {
    this.columnChange.emit(e.columns);
    this.columnVisibilityArgs = e;
  }

  onColumnReorder(e: ColumnReorderEvent): void {
    if (e.newIndex < 0) {
      e.preventDefault();
      return;
    }

    this.reorderSubject$.next(e);

    if (!this.isLockedByButton) {
      this.lockDelaySubscription$ = asapScheduler.schedule(() => {
        this.lockSubject$.next(null);
      }, 300);
    }
    this.isLockedByButton = false;
  }

  onColumnLocked(e): void {
    if (this.lockDelaySubscription$) {
      this.lockDelaySubscription$.unsubscribe();
    }

    this.lockSubject$.next(e);
  }

  getLink(metadataField: MetaDataField, dataItem: T): string {
    if (metadataField.displayControl?.linkSource) {
      return metadataField.displayControl.linkSource;
    }

    switch (metadataField.field) {
      case RestockSuggestionItemField.sku:
        return amazonInventoryLink(
          dataItem[RestockSuggestionItemField.sku],
          this.companyService.currentCompany()?.marketplaceId
        );

      case RestockSuggestionItemField.productName:
        return amazonProductLink(
          dataItem[RestockSuggestionItemField.asin],
          this.companyService.currentCompany()?.marketplaceId
        );

      case RestockSuggestionItemField.asin:
        return amazonInventoryLink(
          dataItem[RestockSuggestionItemField.asin],
          this.companyService.currentCompany()?.marketplaceId
        );
    }
  }

  checkSortMenuColumns(field: string) {
    return field !== ItemField.imageUrl;
  }

  onSearch({
    filters,
    searchTerm,
  }: {
    filters: CompositeFilterDescriptor[];
    searchTerm: string;
  }): void {
    this.searchFilters = filters;
    this.searchChange.emit(searchTerm);
    this.searchTerm = searchTerm;

    this.gridState.skip = 0;
    this.onStateChange(this.gridState);
  }

  getSizeTier(dataItem: RestockSuggestion): string {
    return this.customSizeTier(dataItem?.oversized);
  }

  customSizeTier(sizeTier: string): string {
    sizeTier = sizeTier?.replace(/[A-Z]/g, ' $&')?.trim();
    return sizeTier?.search('Us') >= 0 ? sizeTier?.split('Us')[1] : sizeTier;
  }

  openEstimatedMarginCalcModal(dataItem: RestockSuggestion) {
    const modalRef = this.modalService.open(
      EstimatedMarginCalculationModalComponent,
      {
        size: 'estimated-custom' as string,
        backdrop: 'static',
        keyboard: false,
        centered: true,
      }
    );
    modalRef.componentInstance.restockSuggestion = dataItem;
    modalRef.result
      .then((restockSuggestion: RestockSuggestion) => {
        const gridDataResult = this.grid.data as GridDataResult;

        this.gridItems = {
          total: gridDataResult?.total || 0,
          data:
            this.transformData(
              gridDataResult.data?.map((d: RestockSuggestion) =>
                d.key === restockSuggestion.key ? restockSuggestion : d
              )
            ) || [],
        };
      })
      .catch(() => {});
  }

  openEditItemModal(dataItem: RestockSuggestion) {
    forkJoin([
      this.metadataService.getItemMetaData(),
      this.itemService.getById(
        dataItem.key,
        this.currentCompany?.marketplaceId,
        this.currentCompany?.currencyCode
      ),
    ])
      .pipe(this.autoCleanUp())
      .subscribe(([metadata, item]) => {
        const modalRef = this.modalService.open(GenericItemModalComponent, {
          size: 'xl' as string,
          backdrop: 'static',
          keyboard: false,
        });
        modalRef.componentInstance.modalTitle = 'Change Item Information';
        modalRef.componentInstance.metadataFields = metadata.fields;
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.item.syncedFields = [...this.additionalReadOnlyCols]
        modalRef.componentInstance.currentCompany = this.currentCompany;
        modalRef.componentInstance.resourceService = this.itemService;
        modalRef.componentInstance.forbiddenFields = [
          ItemField.history,
          ItemField.links,
          ItemField.imageUrl,
          ItemField.packageWeight,
          ItemField.prepGuide,
          ItemField.dimensionalWeight,
          ItemField.hazmat,
          ItemField.oversized,
          ItemField.category,
          ItemField.upc,
          ItemField.ean,
          ItemField.isHidden,
          ItemField.useHistoryOverride,
          ItemField.useLostSalesOverride,
          ItemField.createdAt,
          ItemField.inboundPrice,
          ItemField.inboundSalesLast30Days,
          ItemField.inboundAvailable,
          ItemField.inboundFcTransfer,
          ItemField.inboundCustomerOrder,
          ItemField.inboundUnfulfillable,
          ItemField.inboundAlert,
          ItemField.inboundWorking,
          ItemField.mwsFulfillmentFee,
          ItemField.reserved,
          ItemField.fba,
          ItemField.lowestFba,
          ItemField.nonFba,
          ItemField.lowestNonFba,
          ItemField.soldBy,
          ItemField.newBuyBox,
          ItemField.inboundShipped,
          ItemField.inboundReceiving,
          ItemField.referralFee,
          ItemField.listPrice,
          ItemField.condition,
          ItemField.tag,
          ItemField.useBackfill,
          RestockSuggestionItemField.previousValue
        ];

        modalRef.result.then(
          (restockSuggestion) => {
            if (!restockSuggestion) {
              this.loadGridItems();
              return;
            }

            const gridDataResult = this.grid.data as GridDataResult;

            this.gridItems = {
              total: gridDataResult.total,
              data: this.transformData(
                gridDataResult.data.map((d) =>
                  d.key === restockSuggestion.key ? restockSuggestion : d
                )
              ),
            };
          },
          () => {}
        );
      });
  }

  onInboundAnalyzer(dataItem) {
    const modalRef = this.modalService.open(InboundAnalyzerModalComponent, {
      size: 'inbound-analyzer-custom' as string,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.itemName = dataItem.sku;
    modalRef.componentInstance.itemKey = dataItem.key;
  }

  onSelectStatus(event, data) {
    const newItem = { ...data, status: event };

    this.restockSuggestionService
      .save(newItem, 'key')
      .pipe(this.autoCleanUp())
      .subscribe(
        (res) => {
          this.grid.loading = false;

          this.snotifyService.success(
            `${newItem.sku || newItem.fnsku} updated successfully.`
          );
          this.currentItems.forEach((item) => {
            if (item.sku === newItem.sku) {
              item.status = newItem.status;
            }
          });
          this.loadGridItems();
        },
        (err: any) => {
          this.snotifyService.error(
            `There was an error while updating ${
              newItem.sku || newItem.fnsku
            }. Please try again`
          );
        }
      );
  }

  checkSpecialTextFields(field: RestockSuggestionItemField): boolean {
    const textFields = [
      RestockSuggestionItemField.type,
      RestockSuggestionItemField.alertDate,
      RestockSuggestionItemField.createdAt,
      RestockSuggestionItemField.recommendedWarehouseQty,
      RestockSuggestionItemField.recommendedSupplierQty,
      RestockSuggestionItemField.forecastRecommendedQty,
      RestockSuggestionItemField.expeditedRecommendationQty,
      RestockSuggestionItemField.casePackForecastRecommendedQty,
      RestockSuggestionItemField.casePackSuggReorder,
      RestockSuggestionItemField.casePackSuggShip,
      RestockSuggestionItemField.useReForecastQty,
      RestockSuggestionItemField.useReSupplierQty,
      RestockSuggestionItemField.useReWarehouseQty,
      RestockSuggestionItemField.useReCasePackQty,
    ];

    return !textFields.includes(field);
  }

  checkStatus(data, status: ItemStatus): boolean {
    const currentItem = this.currentItems.find((item) => item.sku === data.sku);

    return currentItem?.status === status;
  }

  openEditTagsModal(tagRow: T): void {
    const indexRow = this.selectedTagRows.findIndex(
      (row) => tagRow[this.keyName] === row[this.keyName]
    );
    if (indexRow === -1) {
      this.selectedTagRows.push(tagRow);
      this.selectedKeyTag = _.groupBy(this.selectedTagRows, this.keyName);
    }

    const modalRef = this.modalService.open(EditTagsModalComponent, {
      size: 'lg' as string,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.selectedTagRows = this.selectedTagRows;
    modalRef.componentInstance.resourceService = this.resourceService;
    modalRef.componentInstance.itemName = this.itemName;
    modalRef.componentInstance.selectedKeyTag = this.selectedKeyTag;
    modalRef.componentInstance.saveTagClicked.subscribe((dataItems) => {
      this.selectedTagRows = dataItems;
      const gridDataResult = this.grid.data as GridDataResult;

      this.gridItems = {
        total: gridDataResult.total,
        data: this.transformData(
          gridDataResult.data.map((d) => {
            const item = dataItems.find((dataItem) => dataItem.key === d.key);
            return item || d;
          })
        ),
      };

      if (this.checkboxSelectAllTag?.nativeElement?.checked) {
        this.checkboxSelectAllTag.nativeElement.checked = false;
      }
      this.selectAllTagRows(false);
    });
  }

  selectTagRows(tagRow: T, addRow: boolean): void {
    if (addRow) {
      this.selectedTagRows.push(tagRow);
    } else {
      this.selectedTagRows = this.selectedTagRows.filter(
        (row) => row[this.keyName] !== tagRow[this.keyName]
      );
    }
    this.selectedKeyTag = _.groupBy(this.selectedTagRows, this.keyName);
  }

  selectAllTagRows(addRow: boolean): void {
    this.selectedTagRows = addRow ? this.items : [];
    this.selectedKeyTag = addRow ? _.groupBy(this.items, this.keyName) : [];
  }

  changeOperator() {
    this.currentOperator = !this.currentOperator;
    this.gridState.filter.logic = this.currentOperator ? Logic.and : Logic.or;
    this.onStateChange(this.gridState);
  }

  onFlagChange(data) {
    this.currentFlagFilter = data;
    this.onStateChange(this.gridState);
  }

  onStatusChange(data) {
    this.currentStatusFilter = data;
    this.onStateChange(this.gridState);
  }

  onSupplierChange(data) {
    this.currentSupplierFilters = data;
    this.onStateChange(this.gridState);
  }

  handleOrderQtyCheckboxClick(event, dataItem: RestockSuggestion): void {
    const checked = event.target.checked;

    if (
      Number(dataItem.suggReorder) === 0 &&
      Number(dataItem.suggShip) === 0 &&
      checked
    ) {
      event.target.checked = false;
      this.snotifyService.info(
        `Item ${dataItem.sku} does not has a suggested reorder quantity/ship quantity. Please enter the preferred order quantity yourself.`
      );
      return;
    }

    const shipmentQty =
      Number(dataItem.suggReorder) ||
      _.min(Number(dataItem.suggShip), dataItem.localQty);
    dataItem.orderQty = shipmentQty;

    if (!this.currentShipmentOption?.value) {
      !dataItem.supplier
        ? this.getItemAndMetadata(dataItem?.key)
            .pipe(this.autoCleanUp())
            .subscribe(([metadata, item]) => {
              this.openCreateShipmentModal(
                dataItem,
                event,
                null,
                metadata,
                item
              );
            })
        : this.openCreateShipmentModal(dataItem, event);

      return;
    }

    if (checked) {
      this.shipmentDetailService
        .save(
          {
            shipmentItemKey: dataItem?.shipmentItemKey || null,
            shipmentQty,
            restockKey: this.currentShipmentOption?.value,
            itemKey: dataItem.key,
          },
          'shipmentItemKey'
        )
        .pipe(
          this.autoCleanUp(),
          tap(
            (newShipmentDetail: ShipmentDetail) => {
              this.updateItemQtyInShipment(dataItem, newShipmentDetail);
              dataItem.pending = Number(dataItem.pending) + shipmentQty;
              this.snotifyService.success(
                'Add/Save item to shipment successful.'
              );
            },
            () => {
              event.target.checked = false;
              this.snotifyService.error('Add/Save item to shipment fail.');
            }
          )
        )
        .subscribe();
      return;
    }

    if (dataItem.restockKey) {
      this.shipmentDetailService
        .delete(dataItem.shipmentItemKey)
        .pipe(this.autoCleanUp())
        .subscribe(
          () => {
            this.changeItemsInShipment(dataItem);
            dataItem.pending =
              Number(dataItem.pending) - Number(dataItem.orderQty);
            dataItem.orderQty = 0;
            this.snotifyService.success(
              'Delete item from shipment successful.'
            );
          },
          () => {
            event.target.checked = true;
            this.snotifyService.error('Delete item from shipment fail.');
          }
        );
    }
  }

  handleOrderQtyInputBlur(event, dataItem: RestockSuggestion): void {
    const checkBoxElement = (event.target as HTMLElement)
      .previousElementSibling as HTMLInputElement;
    const inputValue = Number(event.target.value);
    if (inputValue < 0 || isNaN(inputValue)) {
      event.target.value = 0;
    }

    dataItem.orderQty = event.target.value;
    checkBoxElement.checked = Boolean(inputValue);

    if (!this.currentShipmentOption?.value && Boolean(inputValue)) {
      !dataItem.supplier
        ? this.getItemAndMetadata(dataItem?.key)
            .pipe(this.autoCleanUp())
            .subscribe(([metadata, item]) => {
              this.openCreateShipmentModal(
                dataItem,
                event,
                checkBoxElement,
                metadata,
                item
              );
            })
        : this.openCreateShipmentModal(dataItem, event, checkBoxElement);

      return;
    }

    if (inputValue > 0) {
      this.shipmentDetailService
        .save(
          {
            shipmentItemKey: dataItem?.shipmentItemKey || null,
            shipmentQty: inputValue,
            restockKey: this.currentShipmentOption?.value,
            itemKey: dataItem.key,
          },
          'shipmentItemKey'
        )
        .pipe(this.autoCleanUp())
        .subscribe(
          (updatedShipmentItem: ShipmentDetail) => {
            this.updateItemQtyInShipment(dataItem, updatedShipmentItem);
            dataItem.pending = Number(dataItem.pending) + inputValue;
            this.snotifyService.success(
              `Add/Save item to shipment successful.`
            );
          },
          () => {
            event.target.value = 0;
            checkBoxElement.checked = false;
            this.snotifyService.error('Add/Save item to shipment fail.');
          }
        );
      return;
    }

    if (dataItem.restockKey) {
      this.shipmentDetailService
        .delete(dataItem.shipmentItemKey)
        .pipe(this.autoCleanUp())
        .subscribe(
          () => {
            this.changeItemsInShipment(dataItem);
            checkBoxElement.checked = false;
            dataItem.pending = Number(dataItem.pending) - dataItem.shipmentQty;
            this.snotifyService.success(
              'Delete item from shipment successful.'
            );
          },
          () => {
            event.target.value = dataItem.shipmentQty;
            checkBoxElement.checked = true;
            this.snotifyService.error('Delete item from shipment fail.');
          }
        );
    }
  }

  // Handle open create shipment modal
  openCreateShipmentModal(
    dataItem: RestockSuggestion,
    event?,
    checkBoxElement?: HTMLInputElement,
    metadata?: MetaData,
    items?: Item
  ) {
    const modalRef = this.modalService.open(CreateShipmentModalComponent, {
      size: 'lg' as string,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.metadataFields = metadata?.fields;
    modalRef.componentInstance.itemData = items;
    modalRef.componentInstance.supplier = this.decodedSupplierName;
    modalRef.componentInstance.restockSuggestion = {
      ...dataItem,
      orderQty: dataItem.orderQty,
    };

    modalRef.result.then(
      ({ shipment }: { shipment: Shipment }) => {
        this.snotifyService.success('Create shipment successfully.');
        this.setShipmentData(shipment);
        this.currentFbaShipment.emit(this.currentShipmentOption);
        this.onStateChange(this.gridState, false);
      },
      () => {
        this.changeItemsInShipment(dataItem);
        if (event) {
          event.target.checked = false;
        }

        if (checkBoxElement && event) {
          event.target.value = 0;
        }

        if (!checkBoxElement && !event) {
          dataItem.orderQty = 0;
        }
      }
    );
  }

  getItemAndMetadata(key: string): Observable<[MetaData, Item]> {
    return forkJoin([
      this.metadataService.getItemMetaData(),
      this.itemService.getById(key),
    ]);
  }

  resetSupplierFilter(supplierName: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [RestockSuggestionSupplierField.vendorName]:
          encodeURIComponent(supplierName),
      },
      queryParamsHandling: 'merge',
    });

    this.currentSupplierFilters = [{ text: supplierName, value: supplierName }];
  }

  onDeleteShipment() {
    Swal.fire({
      title: 'Delete Shipment?',
      html: `<div>Please confirm that you would like to delete <strong>${this.currentShipmentOption?.text}</strong> shipment on Seller Central</div>`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((willDelete) => {
      //if not dismissed.
      if (!willDelete.dismiss) {
        this.shipmentService
          .delete(this.currentShipmentOption?.value)
          .pipe(this.autoCleanUp())
          .subscribe(() => {
            this.isDisableSupplierFilter = false;
            this.currentShipmentItems = [];

            this.removeShipmentData();
            this.resetSupplierFilter(this.decodedSupplierName);
            this.onStateChange(this.gridState, false);
            this.snotifyService.success('Delete  shipment successful.');
          });
      }
    });
  }

  setShipmentData(shipment: Shipment) {
    // Add new shipment name if not exist in the dropdown
    if (!this.currentShipmentOption?.value) {
      this.shipmentsData = [
        {
          text: shipment.shipmentName,
          value: shipment.key,
        },
      ].concat(this.shipmentsData);
    }

    this.currentShipmentOption = {
      text: shipment.shipmentName,
      value: shipment.key,
    };
  }

  // Remove shipment out of dropdown when shipment is deleted
  removeShipmentData() {
    this.selectedItemKeys = [];
    this.shipmentsData = this.shipmentsData.filter(
      (s) => s.value !== this.currentShipmentOption?.value
    );

    this.currentShipmentOption = this.shipmentDefault;
  }

  changeItemsInShipment(restockSuggestion: RestockSuggestion) {
    restockSuggestion.restockKey = null;
    restockSuggestion.shipmentItemKey = null;
    restockSuggestion.orderQty = 0;
    restockSuggestion.shipmentQty = 0;
    this.selectedItemKeys = this.selectedItemKeys.filter(
      (k) => k !== restockSuggestion.key
    );
  }

  updateItemQtyInShipment(
    restockSuggestion: RestockSuggestion,
    updatedShipmentDetail: ShipmentDetail
  ) {
    restockSuggestion.restockKey = updatedShipmentDetail.restockKey;
    restockSuggestion.shipmentItemKey = updatedShipmentDetail.shipmentItemKey;
    restockSuggestion.orderQty = updatedShipmentDetail.shipmentQty;
    restockSuggestion.shipmentQty = updatedShipmentDetail.shipmentQty;
    if (this.selectedItemKeys?.includes(restockSuggestion.key)) {
      return;
    }

    this.selectedItemKeys.push(restockSuggestion.key);
  }

  isColumnSortable(field: string): boolean {
    return !this.unsortableColumns.includes(field);
  }

  getSpecialClasses(field: RestockSuggestionItemField): string {
    switch (field) {
      case RestockSuggestionItemField.tags:
        return 'd-flex justify-content-between';

      case RestockSuggestionItemField.useReForecastQty:
      case RestockSuggestionItemField.useReSupplierQty:
      case RestockSuggestionItemField.useReWarehouseQty:
      case RestockSuggestionItemField.useReCasePackQty:
      case RestockSuggestionItemField.useExpeditedRecommendationQty:
        return 'd-flex flex-row-reverse justify-content-end';
    }
  }

  applyExpediteFilter(selected: boolean) {
    this.isFilteringExpeditedQty = selected;
    this.onStateChange(this.gridState);
  }

  handleGridViewChange(e: IGridViewChangeEvent): void {
    const { selectedGridView, columns, gridState, searchTerm } = e;
    this.userSelectedGridView = selectedGridView;

    this.gridViewChange.emit({
      selectedGridView,
      columns,
      gridState,
      searchTerm,
    });

    this.searchTerm = searchTerm;
    this.currentSort = gridState.sort;
    this.loadAdditionalFilters(gridState);

    this.onStateChange(gridState, false);
  }

  checkCellHasIcon(field: RestockSuggestionItemField): boolean {
    return [
      RestockSuggestionItemField.forecastRecommendedQty,
      RestockSuggestionItemField.recommendedSupplierQty,
      RestockSuggestionItemField.recommendedWarehouseQty,
      RestockSuggestionItemField.expeditedRecommendationQty,
      RestockSuggestionItemField.casePackForecastRecommendedQty,
      RestockSuggestionItemField.casePackSuggShip,
      RestockSuggestionItemField.casePackSuggReorder,
    ].includes(field);
  }

  onMapDataToOrderQty(
    data: RestockSuggestion,
    field: RestockSuggestionItemField
  ) {
    switch (field) {
      case RestockSuggestionItemField.forecastRecommendedQty:
      case RestockSuggestionItemField.recommendedSupplierQty:
      case RestockSuggestionItemField.expeditedRecommendationQty:
      case RestockSuggestionItemField.casePackForecastRecommendedQty:
      case RestockSuggestionItemField.casePackSuggReorder:
        data.orderQty = Number(data[field]);
        break;

      case RestockSuggestionItemField.recommendedWarehouseQty:
        if (Number(data.localQty) >= 0) {
          data.orderQty =
            Number(data[field]) > Number(data.localQty)
              ? Number(data.localQty)
              : Number(data[field]);
        }
        break;

      case RestockSuggestionItemField.casePackSuggShip:
        if (Number(data.localQty) >= 0) {
          data.orderQty =
            Number(data[field]) > Number(data.localQty)
              ? Number(data.localQty) -
                (Number(data.localQty) % Number(data.casePackQuantity))
              : Number(data[field]);
        }
    }
    this.updateShipmentOrderQty(data);
  }

  updateShipmentOrderQty(data: RestockSuggestion): void {
    if (!this.currentShipmentOption?.value) {
      !data.supplier
        ? this.getItemAndMetadata(data?.key)
            .pipe(this.autoCleanUp())
            .subscribe(([metadata, item]) => {
              this.openCreateShipmentModal(data, null, null, metadata, item);
            })
        : this.openCreateShipmentModal(data);

      return;
    }

    if (!this.currentShipmentOption?.value) {
      return;
    }

    this.shipmentDetailService
      .save(
        {
          shipmentItemKey: data?.shipmentItemKey || null,
          shipmentQty: data.orderQty,
          restockKey: this.currentShipmentOption?.value,
          itemKey: data.key,
        },
        'shipmentItemKey'
      )
      .pipe(
        this.autoCleanUp(),
        tap(
          (newShipmentItem: ShipmentDetail) => {
            this.updateItemQtyInShipment(data, newShipmentItem);

            data.pending = Number(data.pending) + data.orderQty;
            this.snotifyService.success(
              `Add/Save item to shipment successful.`
            );
          },
          () => {
            data.orderQty = data.shipmentQty || 0;
            this.snotifyService.error('Add/Save item to shipment fail.');
          }
        )
      )
      .subscribe();
  }

  onDetailPO(data: {
    dataItem: RestockSuggestion;
    modalType: ModalType;
    field: RestockSuggestionItemField;
  }): void {
    const { dataItem, modalType, field } = data;

    switch (modalType) {
      case ModalType.ItemCard: {
        this.itemService
          .getById(dataItem.key)
          .pipe(this.autoCleanUp())
          .subscribe((item) => {
            const modalRef = this.modalService.open(ItemCardDetailComponent, {
              size: 'xl' as any,
              backdrop: 'static',
              keyboard: false,
            });
            modalRef.componentInstance.choosenItem = item;
          });

        break;
      }

      case ModalType.SalesVelocity: {
        const modalRef = this.modalService.open(
          SaleVelocityCalculationComponent,
          {
            size: 'sale-velocity-custom' as string,
            backdrop: 'static',
            keyboard: false,
          }
        );
        modalRef.componentInstance.choosenItem = dataItem;
        modalRef.componentInstance.restockType = [
          RestockSuggestionItemField.recommendedWarehouseQty,
          RestockSuggestionItemField.casePackSuggShip,
        ].includes(field)
          ? RestockType.Warehouse
          : RestockType.Supplier;
        modalRef.result.then((updatedDailySalesRate) => {
          if (!updatedDailySalesRate?.hasAdjustedToggleChanged) {
            return;
          }

          const { itemKey, suggReorder, suggShip } =
            updatedDailySalesRate || {};
          const gridData = this.grid.data as GridDataResult;
          this.gridItems = {
            total: gridData.total,
            data: gridData.data.map((i) => {
              if (i.key !== itemKey) {
                return i;
              }

              i.suggReorder = i.recommendedSupplierQty = suggReorder;
              i.suggShip = i.recommendedWarehouseQty = suggShip;

              return i;
            }),
          };
        });

        break;
      }
    }
  }

  updateCasePackQtyToItems(newItems: any[], field: RestockSuggestionItemField) {
    newItems.forEach((item: RestockSuggestion) => {
      switch (field) {
        case RestockSuggestionItemField.useReWarehouseQtyByCasePackQty:
          if (item.casePackSuggShip > 0 && item.localQty > 0) {
            item.orderQty =
              item.casePackSuggShip > item.localQty
                ? item.localQty
                : item.casePackSuggShip;
          }
          return;

        case RestockSuggestionItemField.useReForecastQtyByCasePackQty:
          if (item.casePackForecastRecommendedQty > 0) {
            item.orderQty = item.casePackForecastRecommendedQty;
          }
          return;

        case RestockSuggestionItemField.useReSupplierQtyByCasePackQty:
          if (item.casePackSuggReorder > 0) {
            item.orderQty = item.casePackSuggReorder;
          }
          return;
      }
    });
  }

  onUseReCasePackQty(event: MouseEvent, field: RestockSuggestionItemField) {
    this.useReCasePackQtyCheckBox.nativeElement.checked = [
      RestockSuggestionItemField.useReCasePackQty,
      RestockSuggestionItemField.useReForecastQtyByCasePackQty,
      RestockSuggestionItemField.useReSupplierQtyByCasePackQty,
      RestockSuggestionItemField.useReWarehouseQtyByCasePackQty,
    ].includes(field);
    if (field === RestockSuggestionItemField.useReCasePackQty) {
      return;
    }

    this.oldItems = _.cloneDeep(this.items);
    this.updateCasePackQtyToItems(this.items, field);

    if (this.currentShipmentOption?.value) {
      this.shipmentService
        .updateShipmentOrderQty(
          this.currentShipmentOption?.value,
          field,
          true,
          this.decodedSupplierName
        )
        .pipe(
          this.autoCleanUp(),
          tap(
            ({ waitForJob }) => {
              this.useReCasePackQtyCheckBox.nativeElement.checked = false;
              if (waitForJob) {
                this.notifyLongJob(true);
                return;
              }

              this.onStateChange(this.gridState, false);
              this.snotifyService.success(`Update shipment successfully.`);
            },
            () => {
              this.useReCasePackQtyCheckBox.nativeElement.checked = false;
            }
          )
        )
        .subscribe();
      return;
    }

    this.shipmentService
      .checkCanApplyRecommendedQty(this.decodedSupplierName, field)
      .pipe(
        tap((isHasValidOrderQty) => {
          if (!isHasValidOrderQty) {
            this.useReCasePackQtyCheckBox.nativeElement.checked = false;
            this.snotifyService.info(
              `Items don't have a suggested reorder quantity/ship quantity. Please enter the preferred order quantity yourself.`
            );

            return;
          }

          const modalRef = this.modalService.open(
            CreateShipmentModalComponent,
            {
              size: 'lg' as string,
              backdrop: 'static',
              keyboard: false,
            }
          );

          const shipmentItems = _.cloneDeep(
            this.items?.filter((item) => item.orderQty > 0)
          );
          modalRef.componentInstance.isMultiple = true;
          modalRef.componentInstance.supplier = this.decodedSupplierName;
          modalRef.componentInstance.recommendedField = field;
          modalRef.componentInstance.restockSuggestions = shipmentItems;
          modalRef.componentInstance.restockSuggestion = shipmentItems[0];
          modalRef.result.then(
            ({ shipment }: { shipment: Shipment }) => {
              this.useReCasePackQtyCheckBox.nativeElement.checked = false;
              this.setShipmentData(shipment);
              this.currentFbaShipment.emit(this.currentShipmentOption);
              if (shipment?.waitForJob) {
                this.notifyLongJob();
                return;
              }

              this.onStateChange(this.gridState, false);
              this.snotifyService.success(`Create shipment successfully.`);
            },
            () => {
              this.useReCasePackQtyCheckBox.nativeElement.checked = false;

              const gridData = this.grid.data as GridDataResult;
              this.gridItems = {
                total: gridData.total,
                data: this.oldItems,
              };
            }
          );
        })
      )
      .subscribe();
  }

  updateItemsOrderQty(newItems: any[], field: RestockSuggestionItemField) {
    newItems.forEach((item: RestockSuggestion) => {
      switch (field) {
        case RestockSuggestionItemField.useReWarehouseQty:
          if (item.recommendedWarehouseQty > 0 && item.localQty > 0) {
            item.orderQty =
              item.recommendedWarehouseQty > item.localQty
                ? item.localQty
                : item.recommendedWarehouseQty;
          }
          return;

        case RestockSuggestionItemField.useReForecastQty:
          if (item.forecastRecommendedQty > 0) {
            item.orderQty = item.forecastRecommendedQty;
          }
          return;

        case RestockSuggestionItemField.useReSupplierQty:
          if (item.recommendedSupplierQty > 0) {
            item.orderQty = item.recommendedSupplierQty;
          }
          return;

        case RestockSuggestionItemField.useExpeditedRecommendationQty:
          if (item.expeditedRecommendationQty > 0) {
            item.orderQty = item.expeditedRecommendationQty;
          }
          return;
      }
    });
  }

  onUseOrderQty(event: MouseEvent, field: RestockSuggestionItemField) {
    const checkBoxElement = event.target as HTMLInputElement;

    // Only checked 1 checkbox at the same time
    this.useReForecastCheckBox.nativeElement.checked =
      field === RestockSuggestionItemField.useReForecastQty;
    this.useReSupplierCheckBox.nativeElement.checked =
      field === RestockSuggestionItemField.useReSupplierQty;
    this.useReWarehouseCheckBox.nativeElement.checked =
      field === RestockSuggestionItemField.useReWarehouseQty;
    if (this.useReExpeditedCheckBox?.nativeElement) {
      this.useReExpeditedCheckBox.nativeElement.checked =
        field === RestockSuggestionItemField.useExpeditedRecommendationQty;
    }
    this.useReCasePackQtyCheckBox.nativeElement.checked =
      field === RestockSuggestionItemField.useReCasePackQty;

    if (
      !this.useReWarehouseCheckBox?.nativeElement.checked &&
      !this.useReForecastCheckBox?.nativeElement.checked &&
      !this.useReSupplierCheckBox?.nativeElement.checked &&
      !this.useReExpeditedCheckBox?.nativeElement.checked
    ) {
      return;
    }

    this.oldItems = _.cloneDeep(this.items);
    this.updateItemsOrderQty(this.items, field);

    if (this.currentShipmentOption?.value) {
      this.shipmentService
        .updateShipmentOrderQty(
          this.currentShipmentOption?.value,
          field,
          true,
          this.decodedSupplierName
        )
        .pipe(
          this.autoCleanUp(),
          tap(
            ({ waitForJob }) => {
              checkBoxElement.checked = false;
              if (waitForJob) {
                this.notifyLongJob(true);
                return;
              }

              this.onStateChange(this.gridState, false);
              this.snotifyService.success(`Update shipment successfully.`);
            },
            () => {
              checkBoxElement.checked = false;
            }
          )
        )
        .subscribe();
      return;
    }

    this.shipmentService
      .checkCanApplyRecommendedQty(this.decodedSupplierName, field)
      .pipe(
        tap((isHasValidOrderQty) => {
          if (!isHasValidOrderQty) {
            checkBoxElement.checked = false;
            this.snotifyService.info(
              `Items don't have a suggested reorder quantity/ship quantity. Please enter the preferred order quantity yourself.`
            );

            return;
          }

          const modalRef = this.modalService.open(
            CreateShipmentModalComponent,
            {
              size: 'lg' as string,
              backdrop: 'static',
              keyboard: false,
            }
          );

          const shipmentItems = _.cloneDeep(
            this.items?.filter((item) => item.orderQty > 0)
          );

          modalRef.componentInstance.supplier = this.decodedSupplierName;
          modalRef.componentInstance.recommendedField = field;
          modalRef.componentInstance.isMultiple = true;
          modalRef.componentInstance.restockSuggestions = shipmentItems;
          modalRef.componentInstance.restockSuggestion = shipmentItems[0];

          modalRef.result.then(
            ({ shipment }: { shipment: Shipment }) => {
              checkBoxElement.checked = false;
              this.setShipmentData(shipment);
              this.currentFbaShipment.emit(this.currentShipmentOption);
              if (shipment.waitForJob) {
                this.notifyLongJob();
                return;
              }

              this.onStateChange(this.gridState, false);
              this.snotifyService.success(`Create shipment successfully.`);
            },
            () => {
              checkBoxElement.checked = false;

              const gridData = this.grid.data as GridDataResult;
              this.gridItems = {
                total: gridData.total,
                data: this.oldItems,
              };
            }
          );
        })
      )
      .subscribe();
  }

  disableCheckAll() {
    return (
      [
        DefaultRestockSuggestionSupplier.AllSuppliers,
        DefaultRestockSuggestionSupplier.MyWarehouse,
        DefaultRestockSuggestionSupplier.Unassigned,
      ].includes(
        this.decodedSupplierName as DefaultRestockSuggestionSupplier
      ) || this.currentShipmentOption?.value
    );
  }

  applyExtraFilters(filterValue: CompositeFilterDescriptor): void {
    this.filterValue = filterValue;
    this.onStateChange(this.gridState);
  }

  getExtraFilterChipLabel(filter: FilterDescriptor): string {
    if (filter.value === null) {
      filter.value = '';
    }

    return `${this.getDisplayName(
      filter.field as string
    )} ${getReadableKendoOperator(
      filter.operator as string,
      filter.value
    )}${` ${this.getDisplayFilterValue(filter.operator, filter.value)}`}`;
  }

  getDisplayFilterValue(filterOperator, filterValue){
    if(filterOperator === 'isnull' ||  filterOperator === 'isnotnull'){
      return '';
    }
    const value = Number.isNaN(filterValue)
    ? `"${filterValue}"`
    : filterValue;
    return value
  }

  deleteExtraFilter(filter: FilterDescriptor): void {
    this.filterValue = {
      ...this.filterValue,
      filters: this.filterValue.filters.filter(
        (f: FilterDescriptor) =>
          f.field !== filter.field ||
          f.operator !== filter.operator ||
          f.value !== filter.value
      ),
    };
    this.onStateChange(this.gridState);
  }

  openFilterWindow(): void {
    if (!this.filterValue?.filters?.length) {
      this.defaultFilterValue = {
        logic: Logic.and,
        filters: [
          {
            operator: 'eq',
            value: null,
            field: this.filters[0]?.field,
          },
        ],
      };
    }

    this.opened = true;
    asyncScheduler.schedule(() => {
      const btn = document.querySelector(`.k-button[title*=Group]`);
      btn?.setAttribute('hidden', 'true');

      const addFilter= document.querySelector(`.k-button[title='Add Filter']`);
      addFilter?.setAttribute('hidden', 'true');
    });
  }

  getWarehouseQtyUpdatedDate(dataItem: RestockSuggestion): string {
    return dataItem.localQty > 0
      ? `(Updated ${formatDate(
          dataItem.warehouseQtyUpdatedDate,
          this.FORMAT_DATE,
          'en'
        )})`
      : '';
  }

  private handleDownloadLargeFile(notification: BehaviorSubject<any>) {
    this.pusherService.userChannel.bind('notification', (data) => {
      if (data.type === 'close' && data.key === 'downloadCsvStart') {
        const a = document.createElement('a');
        a.href = data.csvLink;
        a.click();
        a.remove();

        notification.next({
          title: 'Success',
          body: 'File downloaded!',
          config: {
            closeOnClick: true,
            timeout: 5000,
            showProgressBar: true,
            type: 'success',
          },
        });

        notification.complete();
        this.syncService.removeDownloadingItem(this.itemName);
        this.isSyncFile = false;
      }

      if (data.type === 'notice' && data.key === 'downloadCsvStart') {
        notification.next({
          title: 'Error',
          body: data.msg.error,
          config: {
            closeOnClick: true,
            timeout: 5000,
            type: 'error',
          },
        });

        notification.complete();
        this.syncService.removeDownloadingItem(this.itemName);
        this.isSyncFile = false;
      }
    });
  }

  filterLocalQty(selected) {
    this.isFilteringLocalQty = selected;
    this.onStateChange(this.gridState);
  }

  filterRecommendedQty(selected) {
    this.isFilteringRecommendedQty = selected;
    this.onStateChange(this.gridState);
  }

  private notifyLongJob(isUpdating = false) {
    this.grid.loading = true;
    const notifyMessage = isUpdating
      ? 'Updating shipment...'
      : 'Creating shipment...';
    const successNotifyMessage = isUpdating
      ? 'Update shipment successfully.'
      : 'Create shipment successfully.';
    const failNotifyMessage = isUpdating
      ? 'Update shipment fail.'
      : 'Create shipment fail.';
    const notification = new Subject<any>();
    this.snotifyService.async(notifyMessage, notification);

    this.pusherService.userChannel.bind('notification', (data) => {
      if (
        data.type === 'close' &&
        data.key === 'shipmentFromRSRecommendationStart'
      ) {
        notification.next({
          body: successNotifyMessage,
          config: {
            closeOnClick: true,
            timeout: 5000,
            showProgressBar: true,
            type: 'success',
          },
        });

        notification.complete();

        if (!isUpdating) {
          this.onStateChange(this.gridState, false);
          return;
        }
        const gridData = this.grid.data as GridDataResult;
        this.gridItems = {
          total: gridData.total,
          data: this.items,
        };
        this.grid.loading = false;
      }

      if (
        data.type === 'notice' &&
        data.key === 'shipmentFromRSRecommendationStart'
      ) {
        notification.next({
          body: failNotifyMessage,
          config: {
            closeOnClick: true,
            timeout: 5000,
            type: 'error',
          },
        });

        notification.complete();
        const gridData = this.grid.data as GridDataResult;
        this.gridItems = {
          total: gridData.total,
          data: this.oldItems,
        };
        this.grid.loading = false;
      }
    });
  }

  loadDeepFilter(state: State) {
    const chartFlag = decodeURIComponent(
      this.route.snapshot.queryParamMap.get('flag')
    );

    const [deepFilter] =
      (state?.filter?.filters as CompositeFilterDescriptor[]) || [];
    if (deepFilter) {
      this.currentOperator = deepFilter?.logic === Logic.and;
      const [flagFilters, statusFilters] = deepFilter?.filters || [];
      this.currentFlagFilter =
        chartFlag && chartFlag !== 'null'
          ? [this.flagOptions.find((f) => f.value === chartFlag)]
          : (flagFilters as CompositeFilterDescriptor)?.filters
              ?.map((f: FilterDescriptor) =>
                this.flagOptions.find((flag) => flag.value === f.value)
              )
              ?.filter((f) => f);

      this.currentStatusFilter = (
        statusFilters as CompositeFilterDescriptor
      )?.filters.map((f: FilterDescriptor) =>
        this.statusOptions.find((status) => status.value === f.value)
      );
    }

    this.correctGridState(state);
  }

  correctGridState(state: State): void {
    state?.filter?.filters.forEach((cf: CompositeFilterDescriptor) => {
      cf.filters.forEach((f: FilterDescriptor) => {
        if (f.field === 'localQty' && f.operator === 'gt' && f.value === 0) {
          this.isFilteringLocalQty = true;
        }
        if (
          (f.field === 'forecastRecommendedQty' &&
            f.operator === 'gt' &&
            f.value === 0) ||
          (f.field === 'suggReorder' && f.operator === 'gt' && f.value === 0) ||
          (f.field === 'suggShip' && f.operator === 'gt' && f.value === 0)
        ) {
          this.isFilteringRecommendedQty = true;
        }
      });
    });
  }

  onResizeColumn(columns) {
    const column = head(columns);
    this.columnResize.emit({
      field: column.column.field,
      newWidth: column.newWidth,
    });
  }

  downloadInitialTemplate() {
    this.syncService
      .downloadInitialTemplate('shipment-item-rs')
      .pipe(this.autoCleanUp(), first())
      .subscribe((res) => {
        const headers: HttpHeaders = res.headers;

        const dispostion: string = headers.get('Content-Disposition') || '';
        const matches = /filename=([^;]+)/gi.exec(dispostion);
        const fileName = (matches[1] || 'untitled')
          .replace('"', '')
          .replace('"', '')
          .trim();

        const a = document.createElement('a');
        const blob = res.body;
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  uploadShipmentItems() {
    const modalRef = this.modalService.open(CreateShipmentModalComponent, {
      size: 'lg' as string,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.isUpload = true;
    modalRef.componentInstance.supplier = this.decodedSupplierName;
    modalRef.result.then(({ shipment }: { shipment: Shipment }) => {
      this.isUpload = true;

      this.setShipmentData(shipment);
      this.currentFbaShipment.emit(this.currentShipmentOption);

      this.openImporter();
    });
  }

  getIsOnOrderDisabled(dataItem: RestockSuggestion, field: RestockSuggestionItemField): 'normal' | 'warehouse' | null {
    if (dataItem[field] <= 0) {
      return 'normal';
    }

    if ([RestockSuggestionItemField.recommendedWarehouseQty, RestockSuggestionItemField.casePackSuggShip].includes(field) && dataItem.localQty <= 0) {
      return 'warehouse';
    }

    return null;
  }

  checkColumnLockedStatus(lockingColumn) {
    if (!lockingColumn) {
      return null;
    }

    const isLocked = !this.lockedColumns.includes(lockingColumn.field);
    if (isLocked) {
      this.lockedColumns = [...this.lockedColumns, lockingColumn.field]
    } else {
      this.lockedColumns = this.lockedColumns.filter((c) => c !== lockingColumn.field);
    }

    return isLocked;
  }


  lockColumnWithButton(event: MouseEvent, field: string) {
    event.stopImmediatePropagation();
    this.isLockedByButton = true;

    const col = this.grid.columns.find((c: any) => c.field === field);
    const isUnlocking = this.lockedColumns.includes(field);
    col.locked = !isUnlocking;

    this.grid.reorderColumn(col, this.lockedColumns?.length + (isUnlocking ? -2 : 0));

    this.lockSubject$.next({columns: [col]});
  }

  shouldDisplayPinIconForIsolateColumn(field: RestockSuggestionItemField) {
    return ![
      RestockSuggestionItemField.s2d,
      RestockSuggestionItemField.casePackForecastRecommendedQty,
      RestockSuggestionItemField.boxLength
    ].includes(field);
  }

  resetLockedColumns() {
    this.isLockedByButton = true;

    const toBeLockedField = RestockSuggestionItemField.sku;
    const col = this.grid.columns.find((c: any) => c.field === toBeLockedField);
    if (!toBeLockedField || !col) {
      return;
    }

    col.locked = true;
    this.isResettingLockedColumns = true;
    this.grid.reorderColumn(col, 0);
    this.lockSubject$.next({columns: [col]});
  }

  getDeepValueOfItem(dataItem: any, field: string, childField: string) {
    return dataItem?.[field]?.[childField];
  }

  inventoryAgeMouseLeaveHandle(inventoryAgeElement) {
    inventoryAgeElement.close();
    this.inventoryAgeCollapses = {
      inventoryAge0to90: true,
      inventoryAge181to330: true
    };
  }

  handlePopoverOpen(popover: NgbPopover) {
    if (this.currentPopup && this.currentPopup?.close) {
      this.currentPopup.close();
      this.currentPopup = null;
    }
    if (popover) {
      this.currentPopup = popover;
    }
  }

  private deleteInProgressShipment() {
    const currentShipmentKey = this.currentShipmentOption?.value;
    return this.shipmentService.delete(currentShipmentKey).pipe(
      tap(() => {
        this.removeShipmentData();
        this.currentFbaShipment.emit(this.currentShipmentOption);
      })
    );
  }

  private loadCsvBoxImporter() {
    this.importer = new CSVBoxImporter(
      environment.csvboxLicenseKeys.asc.shipmentItemRS,
      {},
      () => {},
      { lazy: true }
    );

    this.importer.listen('onSubmit', () => {
      this.isUploadSuccessful = true;
      this.grid.loading = true;
      this.isUpload = false;
    });

    this.importer.listen('onClose', () => {
      if (this.isUploadSuccessful) {
        this.isUploadSuccessful = false;
        return;
      }

      this.grid.loading = false;
      this.isUpload = false;
      this.deleteInProgressShipment().subscribe();
    });
  }

  private openImporter() {
    if (!this.currentShipmentOption.value) {
      return;
    }

    this.grid.loading = true;
    this.importer.setUser({
      user_id: this.currentUser.userId,
      companyType: this.currentCompany.companyType,
      companyKey: this.currentCompany.companyKey,
      isAdmin: this.currentUser.isAdmin,
      fileOptions: btoa(
        JSON.stringify({
          fileType: 'shipmentItemRS',
          isCreateNew: true,
          restockKey: this.currentShipmentOption.value,
        })
      ),
    });
    this.importer.openModal();
  }

  private processFileNotification(data): void {
    switch (data.type) {
      case 'close':
        Swal.fire({
          html: `
            <div >
              Upload file successfully
            </div>
          `,
          type: 'success',
          showCloseButton: true,
          allowOutsideClick: false,
        }).then(() => {
          this.loadGridItems();
        });

        this.selectedRows.clear();
        this.grid.loading = false;
        break;
      case 'notice':
        if (!this.isImportFailModalOpen) {
          this.isImportFailModalOpen = true;
          this.modalService.dismissAll();
          this.altModalService.open(ImportFailModalComponent, {
            data: { message: data },
            backdrop: 'static',
            keyboard: false,
            onError: () => {
              this.isImportFailModalOpen = false;
            },
          });
        }

        this.loadGridItems();
        this.grid.loading = false;
        break;
    }
  }

  private loadGridItemsWithAdvancedFilter() {
    switch (this.itemName) {
      case GridName.RestockSuggestion:
        this.restockSuggestionService
          .getItemsAdvanced({
            type: this.advancedFilter,
            take: this.gridState.take,
            skip: this.gridState.skip,
            filter: this.gridState.filter,
            sort: this.gridState.sort,
            marketplaceId: this.currentCompany?.marketplaceId,
            currencyCode: this.currentCompany?.currencyCode
          })
          .pipe(
            this.autoCleanUp(),
            finalize(()=>{
              asapScheduler.schedule(() => (this.grid.loading = false))
            })
          ).subscribe(({ model, count }) => {
            if (this.itemName === GridName.RestockSuggestion) {
              model = this.transformData(model);
            }

            this.currentItems = model;

            this.selectedTagRows = [];
            this.selectedKeyTag = {};
            this.items = model;
            if (this.checkboxSelectAllTag?.nativeElement?.checked) {
              this.selectedTagRows = this.items;
              this.selectedKeyTag = _.groupBy(this.selectedTagRows, 'key');
            }

            this.selectedItemKeys = model
              .filter((i) => i.restockKey)
              .map((i) => i.key);
            this.gridItems = { total: count, data: model };
            this.isEmptyGrid = count === 0;
          });
        break;
    }
  }

  deleteAdvancedFilter(){
    this.deleteNotificationType.emit(true)
    this.advancedFilter = null
    this.onStateChange(this.gridState);
  }

  onAddFilter(filter){
    filter?.filters.push({operator: 'eq', value: null, field: this.filters[0]?.field })
  }

  onFilterChange(data) {
    if (!data?.filters?.length) {
      this.defaultFilterValue.filters= []
    }
  }
}
