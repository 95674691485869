import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from './resource-service';
import { FileDetails } from './../models/file-details';
import { AmazonS3Response } from './../models/amazon-s3-response';
import { SyncResponse } from './../models/test-connection-response';
import { Company } from '../models/company';
import { AdvancedFilter } from '../infrastructure/enums/advanced-filter.enum';
import { ISyncPayload } from '../infrastructure/interfaces/add-request.interface';
import { GridName } from 'src/app/theme/shared/forecastui/forecast-rxdata-table/forcast-rxdata-table.constant';

@Injectable({
  providedIn: 'root',
})
export class SyncService extends ResourceService<any> {
  private downloadingItems: string[] = [];

  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/sync');
  }

  ascTestConnection(company: Company) {
    return this.httpClient.post<{
      isConnectionOK: boolean;
      shouldShowMaintenanceMessage: boolean;
    }>(`/api/sync/test`, company);
  }

  getAscShouldMaintain() {
    return this.httpClient.get<{ shouldShowMaintenanceMessage: boolean }>(
      `/api/sync/amazon-maintenance`
    );
  }

  companySync(company: any, isInitialSync = false): Observable<SyncResponse> {
    return this.httpClient.post<SyncResponse>(`/api/sync/sync`, {
      ...company,
      lastSyncDate: new Date().getTime(),
      isInitialSync
    });
  }

  syncShipment(
    company: any,
    lastSyncDate: number = new Date().setHours(0, 0, 0, 0)
  ): Observable<SyncResponse> {
    return this.httpClient.post<SyncResponse>(`/api/sync/sync/shipment`, {
      ...company,
      lastSyncDate,
    });
  }

  syncFile(fileName: string, companyKey: string, isPublic = false): Observable<AmazonS3Response> {
    const params = new HttpParams()
      .set('fileName', fileName)
      .set('os', this.getOS())
      .set('isPublic', `${isPublic}`);

    return this.httpClient.get<AmazonS3Response>(
      `/api/file/auth/${companyKey}?${params.toString()}`
    );
  }

  syncVendor(fileDetails: FileDetails, companyKey: string): Observable<string> {
    return this.httpClient.post(`/api/file/auth/${companyKey}`, fileDetails, {
      responseType: 'text',
    });
  }

  uploadFile(fileDetails: FileDetails): any {
    return this.httpClient.post(
      `/api/sync/${fileDetails.fileType}`,
      fileDetails,
      { responseType: 'text' }
    );
  }

  uploadToAmazon(file: File, amazonUrl: string): Observable<any> {
    const contentType = file.type;

    return this.httpClient.put<any>(amazonUrl, file, {
      headers: new HttpHeaders({
        'Content-Type': contentType,
      }),
      reportProgress: true,
      observe: 'events',
    });
  }

  downloadFile(
    payload: ISyncPayload,
    vendorKey?: string,
    query?: any,
    removedItemKeys?: string[],
    advancedFilter?: AdvancedFilter
  ): Observable<HttpResponse<Blob>> {
    // Set default parameters
    payload.headersOnly = payload.headersOnly === undefined ? true : payload.headersOnly ;
    payload.sortAttributes = payload.sortAttributes || null;
    payload.whereClause = payload.whereClause || null;
    removedItemKeys = removedItemKeys || [];

    let sortClause: any = null;

    if (payload.sortAttributes && payload.sortAttributes.length > 0) {
      //turn the telerik sort into the sort object the service is expecting.
      sortClause = {
        name: payload.sortAttributes[0].field,
        direction: payload.sortAttributes[0].dir,
      };
    }

    let params = new HttpParams()
      .set('fields', payload.fields.join(','))
      .set('headersOnly', `${payload.headersOnly}`)
      .set('sort', JSON.stringify(sortClause))
      .set('where', JSON.stringify(payload.whereClause))
      .set('currencyCode',payload.currencyCode)
      .set('marketPlaceId',payload.marketPlaceId)
      .set('summaryOnHandType', encodeURIComponent(payload.summaryOnHandType));

    if (vendorKey !== undefined) {
      params = params.set('vendorKey', vendorKey);
    }

    if (query?.restockKey && query?.shipmentDetailType) {
      params = params.set('restockKey', query.restockKey);
      params = params.set('type', query.shipmentDetailType);
      params = params.set('restockType', query.restockType);
      params = params.set('gridType', query.gridType);
    }

    if (query?.purchaseOrderKey) {
      params = params.set('purchaseOrderKey', (query?.purchaseOrderKey));
    }
    let urlName = payload.itemName.toLowerCase()
    if(payload.itemName === GridName.PurchaseOrder){
      urlName = 'purchase-order'
    }
    this.downloadingItems.push(payload.itemName);

    return this.httpClient.post(
      `${this.apiUrl}/${urlName}/download?${params.toString()}`,
      { removedItemKeys, advancedFilter },
      { responseType: 'blob', observe: 'response' }
    );
  }

  downloadInitialTemplate(itemName: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(
      `${this.apiUrl}/${itemName.toLowerCase()}/initial`,
      { responseType: 'blob', observe: 'response' }
    );
  }

  getOS(): string {
    let yourOS: string;
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf('Windows') !== -1) yourOS = 'windows';
    else if (userAgent.indexOf('Mac') !== -1) yourOS = 'mac';
    else if (userAgent.indexOf('Linux') !== -1) yourOS = 'linux';

    return yourOS;
  }

  getErrorReport(reportLink: string, reportId: string): Observable<string[]> {
    const params = new HttpParams()
      .set('url', reportLink)
      .set('fileName', reportId);

    return this.httpClient.get<string[]>(`${this.apiUrl}/csv-error`, {
      params,
    });
  }

  isItemDownloading(itemName): boolean {
    return this.downloadingItems.indexOf(itemName) >= 0;
  }

  removeDownloadingItem(itemName) {
    const itemIndex = this.downloadingItems.indexOf(itemName);
    if (itemIndex >= 0) {
      this.downloadingItems.splice(itemIndex, 1);
    }
  }

  importAmazonData(successfullyUploadedFileNames: string[]) {
    return this.httpClient.post(
      '/api/file-report/sync',
      successfullyUploadedFileNames
    );
  }

  undoLastUpload(url: string) {
    return this.httpClient.post(`/api/sync/${url}/undo`, {});
  }
}
